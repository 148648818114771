import {
  AreaConstraintV2DtoConstraint,
  AreaConstraintVariant,
  FleetSummaryDto,
  MultiLegVoyageDto,
  ReportType,
  RouteSuggestionDto,
  VesselDto,
  VesselReportDataDto,
  VoyageDto,
  VoyageStatus,
} from "@sofarocean/wayfinder-typescript-client";
import {
  AreaConstraint,
  areaConstraintDtoToAreaConstraint,
} from "contexts/AreaConstraintContexts/useAreaConstraintsCRUD";
import { InfiniteData, QueryClient, QueryKey } from "react-query";
import { QUERY_KEY_STRINGS } from "../shared-types";
import { isNumber } from "./units";

/**
 * Updates an item in a list valued query cache, optionally creating it if it does not exist
 * @param queryClient the `react-query` QueryClient that will update the QueryCache
 * @param key the full query key that identifies the cache
 * @param itemSelector a function that picks the affected item from the list
 * @param replacementItem the item to replace the selected item with
 * @param createIfNotFound whether to create the item if it does not exist in the cache
 */
export function updateQueryCacheListItem<T>(
  queryClient: QueryClient,
  key: QueryKey,
  itemSelector: (item: T) => boolean,
  replacementItem: T,
  createIfNotFound: boolean = false
) {
  queryClient.setQueryData(key, (input: T[] | undefined) => {
    if (!input) return createIfNotFound ? [replacementItem] : [];

    const index = input.findIndex(itemSelector);
    if (typeof index === "number" && index !== -1) {
      // https://tanstack.com/query/v4/docs/react/reference/QueryClient#queryclientsetquerydata
      // `setQueryData` must be performed in an immuatable way.
      return input.map((d, i) => (i === index ? replacementItem : d));
    }
    return createIfNotFound ? [replacementItem, ...input] : [...input];
  });
}

/**
 * Updates an item in a paged list valued query cache
 * @param queryClient the `react-query` QueryClient that will update the QueryCache
 * @param key the full query key that identifies the cache
 * @param itemSelector a function that picks the affected item from the list
 */
export function updatePagedQueryCacheListItem<T>(
  queryClient: QueryClient,
  key: QueryKey,
  itemSelector: (item: T) => boolean,
  replacementItem: T
) {
  if (!queryClient.getQueryData(key)) {
    // if the cache has not been initialized (ie no request has yet been made),
    // then there is no need to try and update it
    return;
  }
  queryClient.setQueryData(
    key,
    (input: { pages: { data: T[] | undefined }[] | undefined } | undefined) => {
      if (input?.pages) {
        const pages = input.pages.map((page) => {
          if (page.data) {
            return {
              ...page,
              // https://tanstack.com/query/v4/docs/react/reference/QueryClient#queryclientsetquerydata
              // `setQueryData` must be performed in an immuatable way.
              data: page.data.map((item) =>
                itemSelector(item) ? replacementItem : item
              ),
            };
          }
          return page;
        });
        return { ...input, pages };
      }
      return { pageParams: [undefined], pages: [] };
    }
  );
}

/**
 * Creates an item in a list valued query cache
 * @param queryClient the `react-query` QueryClient that will update the QueryCache
 * @param key the full query key that identifies the cache
 * @param newItem the item to add
 */
export function createQueryCacheListItem<T>(
  queryClient: QueryClient,
  key: QueryKey,
  newItem: T
) {
  queryClient.setQueryData(
    key,
    (input: T[] | undefined) => (input ? [newItem, ...input] : [newItem]) // TODO make the sort order explicit rather than implicit in this implementation
  );
}

/**
 * Deletes an item in a list valued query cache
 * @param queryClient the `react-query` QueryClient that will update the QueryCache
 * @param key the full query key that identifies the cache
 * @param itemSelector a function that picks the affected item from the list
 */
export function deleteQueryCacheListItem<T>(
  queryClient: QueryClient,
  key: QueryKey,
  itemSelector: (item: T) => boolean
) {
  queryClient.setQueryData(key, (input: T[] | undefined) => {
    const index = input?.findIndex(itemSelector);
    if (input && typeof index === "number" && index !== -1) {
      return input.filter((_, i) => i !== index);
    }
    return input ? { ...input } : [];
  });
}

/**
 * Deletes an item in a paged list valued query cache
 * @param queryClient the `react-query` QueryClient that will update the QueryCache
 * @param key the full query key that identifies the cache
 * @param itemSelector a function that picks the affected item from the list
 */
export function deletePagedQueryCacheListItem<T>(
  queryClient: QueryClient,
  key: QueryKey,
  itemSelector: (item: T) => boolean
) {
  if (!queryClient.getQueryData(key)) {
    // if the cache has not been initialized (ie no request has yet been made),
    // then there is no need to try and update it
    return;
  }
  queryClient.setQueryData(
    key,
    (
      input:
        | {
            pages:
              | { data: T[] | undefined; metadata: { pageSize?: number } }[]
              | undefined;
          }
        | undefined
    ) => {
      if (input?.pages) {
        for (const page of input.pages) {
          if (page.data) {
            const index = page.data.findIndex(itemSelector);
            if (page && typeof index === "number" && index !== -1) {
              page.data = page.data.filter((_, i) => i !== index);
              if (isNumber(page.metadata.pageSize)) page.metadata.pageSize--;
            }
          }
        }
      }
      return input
        ? { ...input, pages: input.pages ? [...input.pages] : input.pages }
        : { pageParams: [undefined], pages: [] };
    }
  );
}

export type BaseTypedWayfinderDtoObject = { __type: string };

export type UndifferentiatedAPISyncObject = Partial<BaseTypedWayfinderDtoObject>;
export type UndifferentiatedSynchronizationDto = {
  created?: UndifferentiatedAPISyncObject[] | undefined;
  updated?: UndifferentiatedAPISyncObject[] | undefined;
  deleted?: UndifferentiatedAPISyncObject[] | undefined;
};

/**
 * Perform CRUD operations on query cache based on a normalized response from crystal-globe
 * @param res the response containing synchronization data from crystal-globe
 * @param queryClient the `react-query` QueryClient that will update the QueryCache
 */
export function synchronizeQueryCache(
  res: UndifferentiatedSynchronizationDto,
  queryClient: QueryClient
) {
  res.updated?.forEach((updated) => {
    // The new Wayfinder API client from the openapi autogenerated endpoints have a __type
    switch ((updated as BaseTypedWayfinderDtoObject).__type) {
      case "Vessel":
        const newVessel = updated as VesselDto;
        updatePagedQueryCacheListItem(
          queryClient,
          getPagedVesselListQueryKey(),
          (m: VesselDto) => m.uuid === newVessel.uuid,
          newVessel
        );
        queryClient.setQueryData(
          getVesselQueryKey(newVessel.uuid),
          (_: any) => newVessel
        );
        break;
      case "Voyage":
        const newVoyage = updated as VoyageDto;
        for (const status in Object.values(VoyageStatus)) {
          // remove this voyage from other status caches (if the status changed, it belongs in a different cache)
          if (status !== newVoyage.status) {
            // TODO this will eventually need to be v2
            deletePagedQueryCacheListItem(
              queryClient,
              getPagedVoyageListQueryKey(status as VoyageStatus), // TODO this will eventually need to be v2
              (v: VoyageDto) => v.uuid === newVoyage.uuid
            );
            queryClient.refetchQueries(
              getPagedVoyageListQueryKey(status as VoyageStatus) // TODO this will eventually need to be v2
            );
          }
        }
        const queryData = queryClient.getQueryData<
          InfiniteData<{ data: VoyageDto[] }>
        >(
          getPagedVoyageListQueryKey(newVoyage.status) // TODO this will eventually need to be v2
        );
        const statusCacheHasVoyage = Boolean(
          queryData?.pages
            .flatMap((p) => p.data)
            .find((cachedVoyage) => cachedVoyage.uuid === newVoyage.uuid)
        );
        // if the corresponding cache does not have the voyage, add it
        if (!statusCacheHasVoyage) {
          // for now, since this is paged, we do not know what page to add it to
          // so just refetch the query
          queryClient.refetchQueries(
            getPagedVoyageListQueryKey(newVoyage.status)
          ); // TODO this will eventually need to be v2
        } else {
          updatePagedQueryCacheListItem(
            queryClient,
            getPagedVoyageListQueryKey(newVoyage.status), // list is unique to voyage
            (v: VoyageDto) => v.uuid === newVoyage.uuid,
            newVoyage
          );
        }
        queryClient.setQueryData(
          getVoyageQueryKey(newVoyage.uuid),
          (_: any) => newVoyage
        );
        break;
      case "RouteSuggestion":
        const newRouteSuggestion = updated as RouteSuggestionDto;
        // If a route suggestion was updated, that usually means a response was given.
        // This would mean that the latest route suggestion is no longer open (needsResponse = false).
        // Instead of just deleting the latest route suggestion from cache (which might be incorrect)
        // or duplicating the logic of the latest route suggestion endpoint, just refetch and let
        // the endpoint return the correct latest suggestion or null.
        queryClient.refetchQueries(
          getLatestRouteSuggestionQueryKey(newRouteSuggestion.voyageUuid)
        );
        break;
      case "AreaConstraint":
      case AreaConstraintVariant.VoyageAreaConstraint:
        const voyageAreaConstraint = areaConstraintDtoToAreaConstraint({
          constraint: {
            ...updated,
            __type: AreaConstraintVariant.VoyageAreaConstraint,
          } as AreaConstraintV2DtoConstraint,
        });
        updateQueryCacheListItem(
          queryClient,
          getVoyageAreaConstraintsQueryKey(voyageAreaConstraint.voyageUuid),
          (ac: AreaConstraint) => ac.uuid === voyageAreaConstraint.uuid,
          voyageAreaConstraint
        );
        break;
      case AreaConstraintVariant.OrganizationAreaConstraint:
        const organizationAreaConstraint = areaConstraintDtoToAreaConstraint(
          {
            constraint: {
              ...updated,
              __type: AreaConstraintVariant.OrganizationAreaConstraint,
            } as AreaConstraintV2DtoConstraint,
          },
          { organizationEditMode: true }
        );
        updateQueryCacheListItem(
          queryClient,
          getOrganizationAreaConstraintsQueryKey(
            organizationAreaConstraint.organizationId
          ),
          (ac: AreaConstraint) => ac.uuid === organizationAreaConstraint.uuid,
          organizationAreaConstraint
        );
        break;
      case AreaConstraintVariant.GlobalAreaConstraint:
        const globalAreaConstraint = areaConstraintDtoToAreaConstraint(
          {
            constraint: updated as AreaConstraintV2DtoConstraint,
          },
          { globalEditMode: true }
        );
        updateQueryCacheListItem(
          queryClient,
          getGlobalAreaConstraintsQueryKey(),
          (ac: AreaConstraint) => ac.uuid === globalAreaConstraint.uuid,
          globalAreaConstraint
        );
        break;
      case "MultiLegVoyage":
        const newMlv = updated as MultiLegVoyageDto;
        updatePagedQueryCacheListItem(
          queryClient,
          getPagedMlvListQueryKey(newMlv.vesselUuid),
          (m: MultiLegVoyageDto) => m.uuid === newMlv.uuid,
          newMlv
        );
        queryClient.setQueryData(
          getMlvQueryKey(newMlv.uuid),
          (_: any) => newMlv
        );
        queryClient.refetchQueries(getPagedMlvListQueryKey(newMlv.vesselUuid));
        break;
      case "VesselReportData":
        const newReport = updated as VesselReportDataDto;
        const vesselUuid = newReport?.guidanceJustification?.vesselUuid;
        if (!vesselUuid) return;
        updatePagedQueryCacheListItem(
          queryClient,
          [
            QUERY_KEY_STRINGS.VESSEL_REPORT_DATA_LIST,
            {
              vesselUuid,
            },
          ],
          (t: VesselReportDataDto) => t.uuid === newReport.uuid,
          newReport
        );
        break;
      case "FleetSummary":
        const newFleetSummary = updated as FleetSummaryDto;
        updatePagedQueryCacheListItem(
          queryClient,
          QUERY_KEY_STRINGS.FLEET_SUMMARY,
          (s: FleetSummaryDto) =>
            s.summary.vesselUuid === newFleetSummary.summary.vesselUuid,
          newFleetSummary
        );
        break;
    }
  });
  res.created?.forEach((created) => {
    switch ((created as BaseTypedWayfinderDtoObject).__type) {
      case "Vessel":
        const newVessel = created as VesselDto;
        // because we are not sure what page the data would be on, just refetch the data
        queryClient.refetchQueries(getPagedVesselListQueryKey());
        queryClient.setQueryData(
          getVesselQueryKey(newVessel.uuid),
          (_: any) => newVessel
        );
        break;
      case "Voyage":
        const newVoyage = created as VoyageDto;
        // because we are not sure what page the data would be on, just refetch the data
        queryClient.refetchQueries(
          getPagedVoyageListQueryKey(newVoyage.status)
        );
        queryClient.setQueryData(
          getVoyageQueryKey(newVoyage.uuid),
          (_: any) => newVoyage
        );
        break;
      case "RouteSuggestion":
        const newRouteSuggestion = created as RouteSuggestionDto;
        queryClient.setQueryData(
          getLatestRouteSuggestionQueryKey(newRouteSuggestion.voyageUuid),
          (_: any) => ({
            __type: "LatestRouteSuggestionForVoyageResponse",
            latestRouteSuggestion: newRouteSuggestion,
          })
        );
        break;
      case "AreaConstraint":
      case AreaConstraintVariant.VoyageAreaConstraint:
        const voyageAreaConstraint = areaConstraintDtoToAreaConstraint({
          constraint: {
            ...created,
            __type: AreaConstraintVariant.VoyageAreaConstraint,
          } as AreaConstraintV2DtoConstraint,
        });

        // NOTE(jordan): In order to modify global/org constraints at the voyage level, we must
        // create a *new* constraint at the voyage level, but the corresponding UUID will already
        // exist in our cache as the global/org constraint, so we update instead of creating
        updateQueryCacheListItem(
          queryClient,
          getVoyageAreaConstraintsQueryKey(voyageAreaConstraint.voyageUuid),
          (ac: AreaConstraint) => ac.uuid === voyageAreaConstraint.uuid,
          voyageAreaConstraint,
          true // create if not found
        );
        break;
      case AreaConstraintVariant.OrganizationAreaConstraint:
        const organizationAreaConstraint = areaConstraintDtoToAreaConstraint(
          {
            constraint: {
              ...created,
              __type: AreaConstraintVariant.OrganizationAreaConstraint,
            } as AreaConstraintV2DtoConstraint,
          },
          { organizationEditMode: true }
        );
        createQueryCacheListItem(
          queryClient,
          getOrganizationAreaConstraintsQueryKey(
            organizationAreaConstraint.organizationId
          ),
          organizationAreaConstraint
        );
        break;
      case AreaConstraintVariant.GlobalAreaConstraint:
        const globalAreaConstraint = areaConstraintDtoToAreaConstraint(
          {
            constraint: created as AreaConstraintV2DtoConstraint,
          },
          { globalEditMode: true }
        );
        createQueryCacheListItem(
          queryClient,
          getGlobalAreaConstraintsQueryKey(),
          globalAreaConstraint
        );
        break;
      case "MultiLegVoyage":
        const newMlv = created as MultiLegVoyageDto;
        queryClient.refetchQueries(getPagedMlvListQueryKey(newMlv.vesselUuid));
        queryClient.setQueryData(
          getMlvQueryKey(newMlv.uuid),
          (_: any) => newMlv
        );
        break;
    }
  });
  res.deleted?.forEach((deleted) => {
    // The new Wayfinder API client from the openapi autogenerated endpoints have a __type
    switch ((deleted as BaseTypedWayfinderDtoObject).__type) {
      case "Vessel":
        const deletedVessel = deleted as VesselDto;
        deleteQueryCacheListItem(
          queryClient,
          getPagedVesselListQueryKey(), // TODO this will eventually need to be v2
          (v: VesselDto) => v.uuid === deletedVessel.uuid
        );
        queryClient.removeQueries(getVesselQueryKey(deletedVessel.uuid));
        break;
      case "Voyage":
        const deletedVoyage = deleted as VoyageDto;
        deletePagedQueryCacheListItem(
          queryClient,
          getPagedVoyageListQueryKey(deletedVoyage.status), // TODO this will eventually need to be v2
          (v: VoyageDto) => v.uuid === deletedVoyage.uuid
        );
        queryClient.removeQueries(getVoyageQueryKey(deletedVoyage.uuid));
        break;
      case "RouteSuggestion":
        const deletedRouteSuggestion = deleted as RouteSuggestionDto;
        queryClient.removeQueries(
          getLatestRouteSuggestionQueryKey(deletedRouteSuggestion.voyageUuid)
        );
        break;
      case "AreaConstraint":
      case AreaConstraintVariant.VoyageAreaConstraint:
        const voyageAreaConstraint = areaConstraintDtoToAreaConstraint({
          constraint: {
            ...deleted,
            __type: AreaConstraintVariant.VoyageAreaConstraint,
          } as AreaConstraintV2DtoConstraint,
        });
        deleteQueryCacheListItem(
          queryClient,
          getVoyageAreaConstraintsQueryKey(voyageAreaConstraint.voyageUuid),
          (ac: AreaConstraint) => ac.uuid === voyageAreaConstraint.uuid
        );
        break;
      case AreaConstraintVariant.OrganizationAreaConstraint:
        const organizationAreaConstraint = areaConstraintDtoToAreaConstraint(
          {
            constraint: {
              ...deleted,
              __type: AreaConstraintVariant.OrganizationAreaConstraint,
            } as AreaConstraintV2DtoConstraint,
          },
          { organizationEditMode: true }
        );
        deleteQueryCacheListItem(
          queryClient,
          getOrganizationAreaConstraintsQueryKey(
            organizationAreaConstraint.organizationId
          ),
          (ac: AreaConstraint) => ac.uuid === organizationAreaConstraint.uuid
        );
        break;
      case AreaConstraintVariant.GlobalAreaConstraint:
        const globalAreaConstraint = areaConstraintDtoToAreaConstraint(
          {
            constraint: {
              ...deleted,
              __type: AreaConstraintVariant.GlobalAreaConstraint,
            } as AreaConstraintV2DtoConstraint,
          },
          { globalEditMode: true }
        );
        deleteQueryCacheListItem(
          queryClient,
          getGlobalAreaConstraintsQueryKey(),
          (ac: AreaConstraint) => ac.uuid === globalAreaConstraint.uuid
        );
        break;
      case "MultiLegVoyage":
        const deletedMlv = deleted as MultiLegVoyageDto;
        deletePagedQueryCacheListItem(
          queryClient,
          getPagedMlvListQueryKey(deletedMlv.vesselUuid),
          (m: MultiLegVoyageDto) => m.uuid === deletedMlv.uuid
        );
        // this is a hack because deletePagedQueryCacheListItem fails to generate any new data when called
        queryClient.refetchQueries(
          getPagedMlvListQueryKey(deletedMlv.vesselUuid)
        );

        queryClient.removeQueries(getMlvQueryKey(deletedMlv.uuid));
        break;
    }
  });
}

export const getRouteSearchQueryKey = (
  departurePortUnlocode: string | undefined,
  arrivalPortUnlocode: string | undefined,
  departurePortRadiusNm: number | undefined,
  arrivalPortRadiusNm: number | undefined
): [string, object] => [
  QUERY_KEY_STRINGS.SEARCH_ROUTES,
  {
    departurePortUnlocode,
    arrivalPortUnlocode,
    departurePortRadiusNm,
    arrivalPortRadiusNm,
  },
];

export const getVoyageAreaConstraintsQueryKey = (
  voyageUuid: string | undefined
): [string, object] => [QUERY_KEY_STRINGS.AREA_CONSTRAINTS, { voyageUuid }];

export const getOrganizationAreaConstraintsQueryKey = (
  organizationId: string | undefined
): [string, object] => [QUERY_KEY_STRINGS.AREA_CONSTRAINTS, { organizationId }];

export const getGlobalAreaConstraintsQueryKey = (): [string, object] => [
  QUERY_KEY_STRINGS.AREA_CONSTRAINTS,
  { global: true },
];

export const getLatestRouteSuggestionQueryKey = (
  voyageUuid: string | undefined
): [string, object] => [
  QUERY_KEY_STRINGS.LATEST_ROUTE_SUGGESTION,
  { voyageUuid },
];

export const getVoyageQueryKey = (
  voyageUuid: string | undefined
): [string, object] => [QUERY_KEY_STRINGS.VOYAGE, { voyageUuid }];

export const getVesselAlertQueryKey = (
  vesselUuid: string | undefined
): [string, object] => [QUERY_KEY_STRINGS.VOYAGE_ALERTS, { vesselUuid }];

export const getUpcomingPathsQueryKey = (): [string] => [
  QUERY_KEY_STRINGS.UPCOMING_POSITIONS,
];

export const getCharterPartyEndVoyageReportQueryKey = (
  voyageUuid: string | undefined
): [string, object] => [
  QUERY_KEY_STRINGS.CHARTER_PARTY_END_VOYAGE_REPORT,
  { voyageUuid },
];

export const getPagedVoyageListQueryKey = (
  status: VoyageStatus | undefined
): string => `${QUERY_KEY_STRINGS.VOYAGES}-${status}`;

export const getVesselQueryKey = (
  vesselUuid: string | undefined
): [string, object] => [QUERY_KEY_STRINGS.VESSEL, { vesselUuid }];

export const getVesselUpdatesQueryKey = (
  vesselUuid: string | undefined
): [string, object] => [QUERY_KEY_STRINGS.VESSEL_UPDATES, { vesselUuid }];

export const getRoutingControlsQueryKey = (
  vesselUuid: string | undefined | null
): [string, object] => [
  QUERY_KEY_STRINGS.ROUTING_CONTROLS_TYPES,
  { vesselUuid },
];

export const getFleetSummaryForVesselQueryKey = (
  vesselUuid: string
): [string, object] => [
  QUERY_KEY_STRINGS.FLEET_SUMMARY_FOR_VESSEL,
  { vesselUuid },
];

export const getOrganizationsQueryKey = (): string =>
  QUERY_KEY_STRINGS.ALL_ORGANIZATION;

export const getVesselGroupsQueryKey = (): string =>
  QUERY_KEY_STRINGS.VESSEL_GROUPS;

export const getPagedVesselListQueryKey = () => QUERY_KEY_STRINGS.VESSELS;

export const getMlvQueryKey = (
  multiLegVoyageUuid: string | undefined
): [string, object] => [
  QUERY_KEY_STRINGS.MULTI_LEG_VOYAGE,
  { multiLegVoyageUuid },
];

export const getPagedMlvListQueryKey = (
  vesselUuid: string | undefined
): [string, object] => [QUERY_KEY_STRINGS.MULTI_LEG_VOYAGES, { vesselUuid }];

export const getUserQueryKey = (): string => QUERY_KEY_STRINGS.CURRENT_USER;

export const getPortsQueryKey = (): string => QUERY_KEY_STRINGS.PORTS;

export const getMlvPresignedUrlsQueryKey = (
  vesselUuid: string | undefined,
  reportType: ReportType
): string =>
  JSON.stringify([
    QUERY_KEY_STRINGS.PRESIGNED_URLS,
    { vesselUuid, reportType },
  ]);

export const getIceLayerQueryKey = (
  pole: string,
  featureType: string,
  zone: string
): string =>
  `${QUERY_KEY_STRINGS.ICE_LAYER_DATA}-${pole}-${featureType}-${zone}`;
