import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import logo from "bundle-data/images/Wayfinder-by-sofar-logo.png";

import AnalyticsContext, { AnalyticsEvent } from "contexts/Analytics";
import { AuthenticationContext } from "contexts/AuthenticationContext";
import { WayfinderTypography } from "DLS";
import { useVessel } from "shared-hooks/data-fetch-hooks/use-vessel";
import useVoyage from "shared-hooks/data-fetch-hooks/use-voyage";
import { useFleetViewVisibility } from "shared-hooks/use-fleet-view-visibility";
import { useRecentVoyage } from "shared-hooks/use-recent-voyage-state";
import { useWayfinderUrl } from "shared-hooks/use-wayfinder-url";
import FallbackLoadingScreen from "../FallbackLoadingScreen";

const SplashScreen: React.FC<{}> = () => {
  const { trackAnalyticsEvent } = useContext(AnalyticsContext);
  const history = useHistory();
  const { recentVoyageUuid } = useRecentVoyage();

  const { metadata, metadataIsLoading } = useContext(AuthenticationContext);

  const userMetadataVesselQuery = useVessel(metadata?.vesselUuid ?? "");
  const {
    vessel: userMetadataVessel,
    isLoading: userMetadataVesselIsLoading,
  } = userMetadataVesselQuery;
  const primaryVoyageUuid = userMetadataVessel?.primaryVoyageUuid;

  const {
    voyage: primaryVoyage,
    voyageIsLoading: primaryVoyageIsLoading,
  } = useVoyage(primaryVoyageUuid || undefined);

  const allowFleetView = useFleetViewVisibility();

  useEffect(() => {
    trackAnalyticsEvent(
      AnalyticsEvent.LoadedSplashScreen,
      `Recent voyage Uuid: ${recentVoyageUuid}`,
      { recentVoyageUuid }
    );
  }, [recentVoyageUuid, trackAnalyticsEvent]);

  const { getUrl } = useWayfinderUrl();
  useEffect(() => {
    if (metadataIsLoading || primaryVoyageIsLoading) return;

    // If there is a voyage uuid stored from the last time they used wayfinder
    // then use that voyage uuid

    // Otherwise, vessel uuid in user metadata (if found) is used to query the
    // list of underway voyages for that vessel and take the one with the latest etd.

    if (recentVoyageUuid) {
      if (!userMetadataVesselIsLoading && userMetadataVessel) {
        const recentVoyageUrl = getUrl("voyage", {
          params: {
            voyageUuid: recentVoyageUuid,
            vesselUuid: userMetadataVessel.uuid,
          },
        });
        trackAnalyticsEvent(
          AnalyticsEvent.RedirectedToPrimaryVoyageFromSplash,
          `Recent voyage Uuid: ${recentVoyageUuid}`,
          { recentVoyageUuid, recentVoyageUrl }
        );
        if (recentVoyageUrl) {
          history.replace(recentVoyageUrl);
        }
      }
    } else if (primaryVoyage) {
      if (!userMetadataVesselIsLoading && userMetadataVessel) {
        const redirectVoyageUuid = primaryVoyage.uuid;
        const redirectUrl =
          redirectVoyageUuid &&
          getUrl("voyage", {
            params: {
              voyageUuid: redirectVoyageUuid,
              vesselUuid: userMetadataVessel.uuid,
            },
          });
        if (!primaryVoyageUuid) {
          trackAnalyticsEvent(
            AnalyticsEvent.RedirectedToLatestVoyageOfAnyStatusFromSplash,
            `Latest voyage of any status Uuid: ${redirectVoyageUuid}, status: ${primaryVoyage.status}`,
            {
              redirectVoyageUuid,
              voyageStatus: primaryVoyage.status,
              redirectUrl,
            }
          );
        } else {
          trackAnalyticsEvent(
            AnalyticsEvent.RedirectedToRecentVoyageFromSplash,
            `Primary voyage Uuid: ${primaryVoyageUuid}`,
            { primaryVoyageUuid, redirectUrl }
          );
        }
        if (redirectUrl) {
          history.replace(redirectUrl);
        }
      }
    } else if (allowFleetView) {
      history.replace("/fleet");
    }
  }, [
    history,
    metadataIsLoading,
    recentVoyageUuid,
    primaryVoyage,
    trackAnalyticsEvent,
    allowFleetView,
    userMetadataVessel,
    getUrl,
    userMetadataVesselIsLoading,
    primaryVoyageUuid,
    primaryVoyageIsLoading,
  ]);

  return metadataIsLoading || primaryVoyageIsLoading ? (
    <FallbackLoadingScreen />
  ) : (
    <Container>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        height="100vh"
        width="100%"
      >
        <Box marginBottom="1em">
          <img
            src={logo}
            alt={"Sofar Wayfinder"}
            style={{ maxWidth: "50vw" }}
          />
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          marginTop="15px"
        >
          <WayfinderTypography
            variant="displayLarge"
            sx={{
              fontSize: 28,
            }}
          >
            Our Support Team will reach out shortly to help set up your first
            voyage.
          </WayfinderTypography>
        </Box>
      </Box>
    </Container>
  );
};

export default SplashScreen;
