import { AisDataDto } from "@sofarocean/wayfinder-typescript-client";
import { isNil, maxBy } from "lodash";
import { DateTime } from "luxon";
import { useContext, useEffect, useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import config from "../../config";
import { CrystalGlobeApiContext } from "../../contexts/CrystalGlobeApiContext";
import { QUERY_KEY_STRINGS } from "../../shared-types";

const failedVesselUuids: Set<string> = new Set([]);

export const useVesselPositionHistory = (vesselUuid: string | undefined) => {
  const { AisDataApi } = useContext(CrystalGlobeApiContext);

  // get the ais history using the vessel UUID
  const {
    data: aisPages,
    isLoading: aisHistoryIsLoading,
    hasNextPage: canFetchMore,
    fetchNextPage: fetchMore,
    isFetching,
  } = useInfiniteQuery(
    [QUERY_KEY_STRINGS.VESSEL_AIS_DATA_LIST, { vesselUuid }],
    async ({ pageParam }) => {
      if (!isNil(vesselUuid)) {
        try {
          const res = await AisDataApi.listVesselAisData({
            vesselUuid,
            afterEventTimestamp:
              DateTime.utc()
                .minus({ days: config.aisHistoryAgeDays })
                .toISO() ?? "",
            cursor: pageParam,
            pageSize: 200,
          });
          return res;
        } catch (e: any) {
          if (e.response.status !== 404) {
            throw e;
          } else {
            if (!failedVesselUuids.has(vesselUuid)) {
              console.warn(
                `Got 404 checking ais location of vessel: ${vesselUuid}`
              );
              failedVesselUuids.add(vesselUuid);
            }
            return Promise.resolve(undefined);
          }
        }
      } else {
        return Promise.resolve(undefined);
      }
    },
    {
      retry: 3,
      refetchInterval: config.aisPollIntervalMs,
      enabled: !isNil(vesselUuid),
      getNextPageParam: (lastGroup) => {
        return lastGroup?.metadata.hasNextPage
          ? lastGroup.metadata.nextCursor
          : undefined;
      },
    }
  );

  useEffect(() => {
    if (canFetchMore && !isFetching) {
      fetchMore();
    }
  }, [canFetchMore, fetchMore, isFetching]);

  const aisHistory = useMemo(
    () =>
      aisPages?.pages
        ?.flatMap((p) => p?.data)
        .filter((p: AisDataDto | undefined): p is AisDataDto => Boolean(p)),
    [aisPages]
  );

  const latestAis = maxBy(aisHistory, "eventTimestamp");

  return { aisHistory, aisHistoryIsLoading, latestAis };
};
