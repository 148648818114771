import useAppSetting from "contexts/AppSettingsContext";
import { AuthenticationContext } from "contexts/AuthenticationContext";
import { errorReportingFetch } from "contexts/CrystalGlobeApiContext";
import { useWayfinderBaseUrl } from "contexts/CrystalGlobeApiContext/use-wayfinder-base";
import { ErrorContext } from "contexts/ErrorContext";
import { ReactElement, useContext, useMemo } from "react";
import { getApis, mockFetch } from "./index";

export const useCrystalGlobeAPIState = () => {
  const { authorizationHeaders } = useContext(AuthenticationContext);
  const init = useMemo(() => ({ headers: authorizationHeaders }), [
    authorizationHeaders,
  ]);
  const { wayfinderBaseUrl } = useWayfinderBaseUrl();
  const { value: enableLocalSessionCloning } = useAppSetting(
    "enableLocalSessionCloning"
  );
  const { setError } = useContext(ErrorContext);
  return useMemo(
    () =>
      getApis(
        init,
        enableLocalSessionCloning ? "" : wayfinderBaseUrl,
        enableLocalSessionCloning ? mockFetch : errorReportingFetch,
        (error: Error, description: string | ReactElement) =>
          setError({
            error,
            description,
            showDetails: true,
            showTitle: true,
            // do not show errors immediately as we may have request retries. Our queryClient will handle
            // surfacing the error dialog when the retries are exhausted
            showNow: false,
          })
      ),
    [init, enableLocalSessionCloning, wayfinderBaseUrl, setError]
  );
};
