import { useCallback, useMemo } from "react";
import useVoyage from "shared-hooks/data-fetch-hooks/use-voyage";
import useAppSetting from "../contexts/AppSettingsContext";

export const useRecentVoyage = () => {
  const {
    setValue,
    value: recentVoyageUuidFromAppSettings,
    isLoading,
  } = useAppSetting("recentVoyageUuid");

  const setRecentVoyage = useCallback(
    (voyageUuid?: string) => {
      if (voyageUuid) {
        setValue(voyageUuid);
      }
    },
    [setValue]
  );

  const { voyage, voyageIsLoading } = useVoyage(
    recentVoyageUuidFromAppSettings,
    {
      allowErrors: true,
    }
  );
  const recentVoyageUuid = voyage?.uuid ?? null;

  return useMemo(() => {
    return {
      setRecentVoyage,
      recentVoyageUuid,
      recentVoyageIsLoading: isLoading || voyageIsLoading,
    };
  }, [setRecentVoyage, recentVoyageUuid, isLoading, voyageIsLoading]);
};
