import { useContext, useEffect } from "react";
import config from "config";
import { getBufferedRoute, unnestMultiPolygons } from "helpers/routes";
import WeatherContext from "contexts/WeatherContext";
import { slicePolygonsToSingleWorldCopies } from "helpers/geometry";
import { Route } from "shared-types/RouteTypes";
import { MapBounds } from "shared-types";
import _ from "lodash";

/**
 * Transform map bounds to geometry that can be used for the region of interest
 * @param bounds
 * @returns
 */
export const getRoiGeometryFromBounds = (bounds: number[]) => {
  return unnestMultiPolygons(
    slicePolygonsToSingleWorldCopies({
      type: "Polygon",
      coordinates: [
        [
          [bounds[0], bounds[1]],
          [bounds[2], bounds[1]],
          [bounds[2], bounds[3]],
          [bounds[0], bounds[3]],
          [bounds[0], bounds[1]],
        ],
      ],
    }).features
  ).geometry;
};

/**
 * Handle all voyage-screen updates to the region of interest from one spot, so that
 * we do not inadvertantly implement competing updates or race conditions.
 *
 * If no bounds and no route are provided, do nothing
 * If the newly computed ROI is not different than the current ROI, do nothing
 *
 * @param readyToSetMapRoi - is the app in a stable state, when an update is safe?
 * @param routeForRoi - use this route for the update, or leave it undefined
 * @param fallbackBoundsForRoi - fallback to these bounds if there is no route
 */
export const useMapRoiUpdater = (
  readyToSetMapRoi: boolean,
  routeForRoi: Route | undefined,
  fallbackBoundsForRoi: MapBounds | undefined
) => {
  const { setRegionOfInterest, currentForecastMetadata } = useContext(
    WeatherContext
  );
  useEffect(() => {
    if (!currentForecastMetadata.initialized || !readyToSetMapRoi) {
      return;
    }

    let geometry;

    if (routeForRoi) {
      geometry = getBufferedRoute(routeForRoi, config.weatherBufferDistanceKm)
        .geometry;
    } else if (fallbackBoundsForRoi) {
      geometry = getRoiGeometryFromBounds(fallbackBoundsForRoi.bounds);
    }

    if (
      geometry &&
      !_.isEqual(
        // do not bother to set if nothing has changed
        currentForecastMetadata.regionOfInterest?.coordinates,
        geometry.coordinates
      )
    ) {
      setRegionOfInterest(geometry);
    }

    // Exclude changes to the `setRegionOfInterest` function from triggering the effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fallbackBoundsForRoi,
    currentForecastMetadata.initialized,
    routeForRoi,
    readyToSetMapRoi,
  ]);
};
