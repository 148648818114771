import { ReactElement, useCallback, useMemo, useState } from "react";
import { ErrorContextType, SetErrorProps } from ".";

/**
 * Global error state used to show a well formed error from anywhere in the app.
 */
const useErrorState: () => ErrorContextType = () => {
  const [errorDescription, setErrorDescription] = useState<
    string | ReactElement
  >();
  const [error, setLocalError] = useState<Error>();
  const [onDismissError, setOnDismissError] = useState<() => void>();
  const [showError, setShowError] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const errorMessage = error?.message;

  const setError = useCallback(
    ({
      error: newError,
      description,
      onDismiss,
      showNow,
      showTitle,
      showDetails,
    }: SetErrorProps) => {
      if (newError.message !== errorMessage) {
        if (showNow) setShowError(true);
        if (showTitle !== undefined) setShowTitle(showTitle);
        if (showDetails !== undefined) setShowDetails(showDetails);
        setLocalError(newError);
        // see https://medium.com/swlh/how-to-store-a-function-with-the-usestate-hook-in-react-8a88dd4eede1
        setOnDismissError(() => onDismiss);
        setErrorDescription(description);
      }
    },
    [errorMessage]
  );

  const handleDismissError = useCallback(() => {
    if (onDismissError) onDismissError();

    setShowError(false);
    setShowTitle(false);
    setShowDetails(false);
    setOnDismissError(undefined);
    setErrorDescription(undefined);
    setLocalError(undefined);
  }, [onDismissError]);

  return useMemo(
    () => ({
      error,
      showError,
      setShowError,
      description: errorDescription,
      hasError: error !== undefined,
      showTitle,
      showDetails,
      setError,
      onDismissError: handleDismissError,
    }),
    [
      error,
      showError,
      setShowError,
      errorDescription,
      handleDismissError,
      setError,
      showDetails,
      showTitle,
    ]
  );
};

export default useErrorState;
