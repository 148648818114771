import React, { ReactElement } from "react";

export type SetErrorProps = {
  error: Error;
  description: string | ReactElement;
  onDismiss?: () => void;
  showTitle?: boolean;
  showDetails?: boolean;
  showNow?: boolean;
};

export type SetContextErrorType = (props: SetErrorProps) => void;

export type ErrorContextType = {
  showError: boolean;
  setShowError: (show: boolean) => void;
  showDetails: boolean;
  showTitle: boolean;
  error: Error | undefined;
  description: string | ReactElement | undefined;
  hasError: boolean;
  onDismissError?: () => void;
  /**
   * Set the error for the global error handler. An error and a description is required.
   * The `onDismiss` callback is called when the user clicked 'OK' to dismiss the error.
   * The error is automatically reset after the user clicks 'OK' and its the responsibility
   * of the caller to make sure the error doesn't repeat immediately.
   */
  setError: SetContextErrorType;
};

const errorContextDefaults: ErrorContextType = {
  showError: false,
  setShowError: () => null,
  showTitle: true,
  showDetails: true,
  error: undefined,
  description: undefined,
  hasError: false,
  onDismissError: () => null,
  setError: (props: SetErrorProps) => null,
};

const ErrorContext = React.createContext<ErrorContextType>(
  errorContextDefaults
);

const ErrorContextProvider: React.FC<{ value: ErrorContextType }> = ({
  children,
  value,
}) => {
  return (
    <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>
  );
};

export { ErrorContext, ErrorContextProvider };
