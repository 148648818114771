import React, { useContext, useEffect, useMemo, useRef } from "react";
import { CircularProgress, SxProps } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { extendedPalette } from "styles/theme";
import AnalyticsContext, { AnalyticsEvent } from "contexts/Analytics";

export type SpinnerProps = {
  color?: string;
  scale?: number;
  waitingFor: string;
  sx?: SxProps;
};

const useStyles = makeStyles<{ color?: string }>()((theme, { color }) => ({
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: color ?? extendedPalette.neutralMedDark,
  },
}));

export const Spinner: React.FC<SpinnerProps> = React.memo(
  ({ color, scale, waitingFor, sx }) => {
    const { trackAnalyticsEvent } = useContext(AnalyticsContext);
    const stylesProp = useMemo(() => ({ color }), [color]);
    const { classes: styles } = useStyles(stylesProp);
    const mountedTimestamp = useRef(Date.now());

    // generate analytics for time spent awaiting the spinner
    useEffect(() => {
      return () => {
        // clean up function records Spinner lifecycle duration on unmount
        const eventData = {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          durationMs: Date.now() - mountedTimestamp.current,
          waitingFor,
        };
        trackAnalyticsEvent(
          AnalyticsEvent.SpinnerShown,
          "Spinner shown",
          eventData
        );
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
      <div className={styles.container}>
        <CircularProgress
          color="inherit"
          size={`${40 * (scale ?? 1)}px`}
          sx={sx}
        />
      </div>
    );
  }
);
