import {
  UpdateVesselDto,
  VesselDto,
} from "@sofarocean/wayfinder-typescript-client";
import AnalyticsContext, { AnalyticsEvent } from "contexts/Analytics";
import { useCallback, useContext, useEffect, useMemo } from "react";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { CrystalGlobeApiContext } from "../../contexts/CrystalGlobeApiContext";
import {
  getPagedVesselListQueryKey,
  getVesselQueryKey,
  getVesselUpdatesQueryKey,
  getVoyageQueryKey,
  synchronizeQueryCache,
} from "../../helpers/crystalGlobeApi";

export function useVessel(vesselUuid?: string) {
  const { VesselsApi } = useContext(CrystalGlobeApiContext);
  const { data: vessel, error, isLoading, refetch } = useQuery(
    getVesselQueryKey(vesselUuid),
    async () => {
      if (vesselUuid) {
        return await VesselsApi.getVessel({ vesselUuid });
      } else {
        return undefined;
      }
    },
    {
      retry: 3,
      refetchInterval: 1000 * 60 * 10, // 10 minutes
      enabled: Boolean(vesselUuid),
    }
  );

  const { data: vesselUpdates, isLoading: vesselUpdatesIsLoading } = useQuery(
    getVesselUpdatesQueryKey(vesselUuid),
    async () =>
      vesselUuid
        ? await VesselsApi.getVesselUpdates({ vesselUuid })
        : undefined,
    {
      retry: 3,
    }
  );

  return useMemo(
    () => ({
      vessel,
      error,
      isLoading,
      refresh: refetch,
      vesselUpdates,
      vesselUpdatesIsLoading,
    }),
    [error, isLoading, vessel, refetch, vesselUpdates, vesselUpdatesIsLoading]
  );
}

export function useUpdateVessel() {
  const queryClient = useQueryClient();

  const { VesselsApi } = useContext(CrystalGlobeApiContext);
  const { trackAnalyticsEvent } = useContext(AnalyticsContext);

  const updateVesselOnServer = useCallback(
    async (vessel: VesselDto) => {
      const updateVesselDto: UpdateVesselDto = {
        ...vessel,
        propellers: vessel.propellers ?? undefined,
      };
      return VesselsApi.updateVessel({
        vesselUuid: vessel.uuid,
        updateVesselDto,
      });
    },
    [VesselsApi]
  );

  const onSaveSuccess = useCallback(
    (vessel: VesselDto) => {
      trackAnalyticsEvent(
        AnalyticsEvent.UpdatedVessel,
        `Updated Vessel with Uuid: ${vessel.uuid} on CrystalGlobe`
      );
    },
    [trackAnalyticsEvent]
  );

  const {
    mutateAsync: updateVessel,
    isLoading: updateVesselIsLoading,
    isError: updateVesselIsError,
    reset: resetUpdateVessel,
  } = useMutation<VesselDto, unknown, { vessel: VesselDto }>(
    async ({ vessel }) => {
      const res = await updateVesselOnServer(vessel);
      synchronizeQueryCache(res.data, queryClient);
      return res.data.updated[0];
    },
    {
      onSuccess: onSaveSuccess,
    }
  );

  return useMemo(
    () => ({
      updateVessel,
      updateVesselIsLoading,
      updateVesselIsError,
      resetUpdateVessel,
    }),
    [
      resetUpdateVessel,
      updateVessel,
      updateVesselIsError,
      updateVesselIsLoading,
    ]
  );
}
export const useVessels = (enabled = true) => {
  const { VesselsApi } = useContext(CrystalGlobeApiContext);

  const {
    data: { pages: vessels = undefined } = {},
    isFetching,
    isLoading,
    hasNextPage: canFetchMore,
    fetchNextPage: fetchMore,
  } = useInfiniteQuery(
    getPagedVesselListQueryKey(),
    async ({ pageParam }) =>
      await VesselsApi.listVessels({ cursor: pageParam }),
    {
      retry: 3,
      refetchInterval: 1000 * 60 * 10, // 10 minutes
      getNextPageParam: (lastGroup, _) => {
        return lastGroup.metadata.hasNextPage
          ? lastGroup.metadata.nextCursor
          : undefined;
      },
      enabled,
    }
  );

  const vesselsAreLoading = useMemo(() => isLoading || canFetchMore, [
    canFetchMore,
    isLoading,
  ]);

  useEffect(() => {
    if (canFetchMore && !isFetching) {
      fetchMore();
    }
  }, [canFetchMore, fetchMore, isFetching]);

  return useMemo(
    () => ({
      vessels:
        vessels
          ?.flatMap((v) => v.data)
          .map((v) => {
            if (v.primaryVoyageUuid === "") {
              v.primaryVoyageUuid = null;
            }
            return v;
          }) ?? ([] as VesselDto[]),
      vesselsAreLoading,
      vesselsAreFetching: isFetching,
    }),
    [isFetching, vessels, vesselsAreLoading]
  );
};
