import {
  RtzJsonDto,
  UpcomingPathDto,
} from "@sofarocean/wayfinder-typescript-client";
import { CrystalGlobeApiContext } from "contexts/CrystalGlobeApiContext";
import { getUpcomingPathsQueryKey } from "helpers/crystalGlobeApi";
import { consoleAndSentryError } from "helpers/error-logging";
import { useContext, useEffect, useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import { useStableDeepComparedReference } from "shared-hooks/use-stable-deepcompared-reference";

const PAGE_SIZE = 50;

export type UpcomingPathDtoWithRtz = UpcomingPathDto & { path: RtzJsonDto };

export const useUpcomingPaths = () => {
  const { VesselsApi } = useContext(CrystalGlobeApiContext);
  const {
    data: upcomingPathDataUnstable,
    hasNextPage: canFetchMore,
    fetchNextPage: fetchMore,
    isFetching,
  } = useInfiniteQuery(
    getUpcomingPathsQueryKey(),
    async ({ pageParam }) => {
      try {
        return await VesselsApi.upcomingPaths({
          cursor: pageParam,
          pageSize: PAGE_SIZE,
        });
      } catch (e) {
        // the error from the typescript client is in e.cause
        consoleAndSentryError("Error fetching upcoming paths", {
          cause: (e as any)?.cause,
        });
        return {
          data: [],
          metadata: { hasNextPage: false, nextCursor: "", pageSize: PAGE_SIZE },
        };
      }
    },
    {
      // the route uuids change every time we fetch
      refetchInterval: false,
      getNextPageParam: (lastGroup) => {
        return lastGroup?.metadata.hasNextPage
          ? lastGroup.metadata.nextCursor
          : undefined;
      },
    }
  );

  useEffect(() => {
    if (canFetchMore && !isFetching) {
      fetchMore();
    }
  }, [canFetchMore, fetchMore, isFetching]);

  // useQuery changes these refs for unknown reasons, and since this is in
  // a context, we want it to stay stable to avoid app-wide rerenders
  const upcomingPathPages = useStableDeepComparedReference(
    upcomingPathDataUnstable?.pages
  );
  return useMemo(
    () =>
      upcomingPathPages
        ?.flatMap((d) => d.data)
        .filter((p: UpcomingPathDto | undefined): p is UpcomingPathDto =>
          Boolean(p)
        ),
    [upcomingPathPages]
  ) as UpcomingPathDtoWithRtz[];
};
