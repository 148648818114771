import { FLEET_VIEW_TABS, FleetViewTab } from "contexts/FleetViewContext";
import { uniq } from "lodash";
import { useCallback, useMemo, useRef } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useRecentVoyage } from "shared-hooks/use-recent-voyage-state";
import {
  BooleanParam,
  NumberParam,
  StringParam,
  UrlUpdateType,
  useQueryParams,
  withDefault,
} from "use-query-params";
import {
  RouteQuantities,
  TimestampedRouteQuantities,
  WeatherQuantities,
} from "../config";
import {
  RasterWeatherLayer,
  UIContextDefaults,
  VectorWeatherLayer,
  WeatherMapLayers,
} from "../contexts/UIContext";
import { useFleetViewVisibility } from "./use-fleet-view-visibility";

export const PRINT_PATH = `/print`;

export type UrlParams = {
  voyageUuid: string;
  mlvUuid: string;
};

type WayfinderUrlMapping =
  | {
      type: "voyage";
      voyageUuid?: string;
      vesselUuid?: string | null | undefined;
      routesToCompare?: string[] | undefined;
    }
  | {
      type: "voyage-print";
      voyageUuid?: string;
    }
  | {
      type: "cp-end-voyage-print";
      mlvUuid?: string;
    }
  | {
      type: "routes";
      voyageUuid?: string;
      vesselUuid?: string | null | undefined;
    }
  | {
      type: "route-detail";
      routeUuid: string;
    }
  | {
      type: "route-edit";
      routeUuid: string;
    }
  | {
      type: "fleet";
    }
  | {
      type: "seakeeping";
      voyageUuid?: string;
      vesselUuid?: string;
    }
  | {
      type: "dashboard";
      voyageUuid?: string;
      vesselUuid?: string;
    }
  | {
      type: "area-constraints";
      voyageUuid?: string;
      vesselUuid?: string;
    }
  | {
      type: "optimization";
      voyageUuid?: string;
      vesselUuid?: string;
    }
  | {
      type: "optimization-history";
      voyageUuid?: string;
      vesselUuid?: string;
    }
  | {
      type: "layers";
      voyageUuid?: string;
      vesselUuid?: string;
    }
  | {
      type: "all-voyages";
      voyageUuid?: string;
      vesselUuid?: string;
    }
  | {
      type: "new-voyage";
      vesselUuid: string;
    }
  | {
      type: "edit-voyage";
      voyageUuid?: string;
    }
  | {
      type: "route-import";
      voyageUuid?: string;
    }
  | {
      type: "vessel";
      vesselUuid?: string;
    }
  | {
      type: "vessel-adherence";
      vesselUuid?: string;
    }
  | {
      type: "vessel-performance";
      vesselUuid?: string;
    }
  | {
      type: "vessel-noons";
      vesselUuid?: string;
    }
  | {
      type: "vessel-reports";
      vesselUuid?: string;
    }
  | {
      type: "vessel-voyage-report";
      vesselUuid?: string;
      mlvUuid?: string;
    }
  | {
      type: "settings";
    }
  | {
      type: "settings-global-area-constraints";
    }
  | {
      type: "settings-organization-area-constraints";
    };

export type PlotQuantities =
  | WeatherQuantities
  | RouteQuantities
  | TimestampedRouteQuantities;
export type WayfinderUrl = WayfinderUrlMapping["type"];
export type WayfinderUrlParam<T> = ExtractActionParameters<
  WayfinderUrlMapping,
  T
>;

type ExtractActionParameters<A, T> = Omit<Extract<A, { type: T }>, "type">;

export type WayfinderUrlOptions<P extends WayfinderUrl> = {
  params?: WayfinderUrlParam<P>;
  replace?: boolean;
};

export type UseWayfinderUrlResult = {
  voyageUuid: string;
  vesselUuid?: string;
  mlvUuid: string;
  routeUuid: string | undefined;
  routeUuidsToCompare: string[];
  routeDetailUUId?: string;
  weatherMapLayers: WeatherMapLayers;
  weatherPlotQuantity: PlotQuantities;
  simulatedHistoricalTime?: Date;
  fleetViewTab?: FleetViewTab;
  rpmAdherenceUuid?: string;

  setWeatherMapLayers: (weatherMapLayers: WeatherMapLayers) => void;
  setWeatherPlotQuantity: (plotQuantity: PlotQuantities) => void;
  setSimulatedHistoricalTime: (simulatedHistoricalTime: string) => void;
  setFleetViewTab: (tab: FleetViewTab) => void;
  setRpmAdherenceUuid: (rpmAdherenceUuid: string | undefined) => void;

  /**
   * Set the URL to a given @see WayfinderUrl.
   */
  setUrl: <P extends WayfinderUrl>(
    path: P,
    options?: WayfinderUrlOptions<P>
  ) => void;

  getUrl: <P extends WayfinderUrl>(
    path: P,
    options?: WayfinderUrlOptions<P>
  ) => string | undefined;

  setRoutesToCompare: (routeUuids: string[]) => void;
};

const weatherMapLayerRasterDefault = UIContextDefaults.weatherMapLayers.raster;
const weatherMapLayerVectorDefault = UIContextDefaults.weatherMapLayers.vector;
const weatherMapLayerPressureDefault =
  UIContextDefaults.weatherMapLayers.showPressure;
const weatherMapLayerShowTropicalStormsDefault =
  UIContextDefaults.weatherMapLayers.showTropicalStorms;
const weatherMapLayerShowNauticalChartsDefault =
  UIContextDefaults.weatherMapLayers.showNauticalCharts;
const weatherMapLayerShowIceLayersDefault =
  UIContextDefaults.weatherMapLayers.showIceLayers;

const plotQuantityDefault: PlotQuantities = "combinedWaves";
const simulatedHistoricalTimeDefault = "";

type WayfinderQuery = {
  routesToCompare?: string;
  plotQuantity?: string;
  simulatedHistoricalTime?: string;
  rpmAdherenceUuid?: string;
};

type WayfinderWeatherQuery = {
  showSpotters?: boolean;
  showPorts?: boolean;
  showPressure?: boolean;
  showTropicalStorms?: boolean;
  showNauticalCharts?: boolean;
  showIceLayers?: boolean;
  raster?: RasterWeatherLayer;
  vector?: VectorWeatherLayer;
};

export const FLEET_VIEW_PATH = `/fleet`;
export const SETTINGS_PATH = `/wayfinder-settings`;
export const GLOBAL_AREA_CONSTRAINT_SETTINGS_PATH = `${SETTINGS_PATH}/global-area-constraints`;
export const ORGANIZATION_AREA_CONSTRAINT_SETTINGS_PATH = `${SETTINGS_PATH}/organization-area-constraints`;

export const VESSEL_DETAILS_BASE_PATH = `/vessel/:vesselUuid`;
export const ALL_VOYAGES_PATH = `${VESSEL_DETAILS_BASE_PATH}/voyages`;
export const CREATE_VOYAGE_PATH = `${VESSEL_DETAILS_BASE_PATH}/new-voyage`;
export const ADHERENCE_PATH = `${VESSEL_DETAILS_BASE_PATH}/adherence`;
export const PERFORMANCE_PATH = `${VESSEL_DETAILS_BASE_PATH}/performance`;
export const NOONS_PATH = `${VESSEL_DETAILS_BASE_PATH}/noons`;
export const REPORTS_PATH = `${VESSEL_DETAILS_BASE_PATH}/reports`;
export const MLV_REPORTS_PATH = `${REPORTS_PATH}/:mlvUuid`;
export const VOYAGE_PATH = `${VESSEL_DETAILS_BASE_PATH}/voyage/:voyageUuid`;

// The Route Explorer can be access with or without a voyage defined in the URL.
export const ROUTE_EXPLORER_PATH = `${VESSEL_DETAILS_BASE_PATH}/route-explorer`;
export const ROUTE_EXPLORER_VOYAGE_PATH = `${VOYAGE_PATH}/route-explorer`;
export const ROUTE_EXPLORER_PATHS = [
  ROUTE_EXPLORER_PATH,
  ROUTE_EXPLORER_VOYAGE_PATH,
];

export const IMPORT_VOYAGE_PATH = `${VOYAGE_PATH}/import`;
export const EDIT_VOYAGE_PATH = `${VOYAGE_PATH}/edit`;
export const AREA_CONSTRAINT_PATH = `${VOYAGE_PATH}/area-constraints`;
export const DASHBOARD_PATH = `${VOYAGE_PATH}/dashboard`;
export const OPTIMIZATION_PATH = `${VOYAGE_PATH}/optimization`;
export const OPTIMIZATION_HISTORY_PATH = `${VOYAGE_PATH}/optimization-history`;
export const SEAKEEPING_PATH = `${VOYAGE_PATH}/seakeeping`;
export const ALL_ROUTES_PATH = `${VOYAGE_PATH}/routes`;
export const ROUTE_PATH = `${ALL_ROUTES_PATH}/:routeUuid`;

export const EDIT_ROUTE_PATH = `${ROUTE_PATH}/edit`;

export const OLD_VOYAGE_PATH = `/voyage/:voyageUuid`;
export const OLD_ALL_ROUTES_PATH = `${OLD_VOYAGE_PATH}/routes`;
export const OLD_ROUTE_PATH = `${OLD_VOYAGE_PATH}/routes/:routeUuid`;
export const OLD_ALL_VOYAGES_PATH = `${VOYAGE_PATH}/all`;
export const OLD_CREATE_VOYAGE_PATH = `${VOYAGE_PATH}/new-voyage`;

/**
 * Returns the voyageUuid and vesselUuid from the `/vessel/:vesselUuid/voyage/:voyageUuid`/ URL.
 * Uses useRouteMatch to be independent from the react router.
 *
 * This is the same problem as discussed in https://github.com/wavespotter/tell-tale/pull/204
 *
 */
export const useWayfinderUrlUuids = (): {
  voyageUuid?: string;
  vesselUuid?: string;
  routeUuid?: string;
} => {
  const match = useRouteMatch<{
    voyageUuid: string;
    vesselUuid: string;
    routeUuid: string;
  }>([
    OLD_ROUTE_PATH,
    ROUTE_PATH,
    OLD_VOYAGE_PATH,
    VOYAGE_PATH,
    VESSEL_DETAILS_BASE_PATH,
  ]);
  return {
    voyageUuid: match?.params.voyageUuid,
    vesselUuid: match?.params.vesselUuid,
    routeUuid: match?.params.routeUuid,
  };
};

/**
 * Returns the standard Wayfinder URL parameters @see UrlParams and any params
 * that are passed as the generic argument.
 */
const useWayfinderUrl = <
  P extends { [K in keyof P]?: string | undefined } = {}
>(): UseWayfinderUrlResult & P => {
  // FIXME intersecting the return type with the generic in the definition of the component lets you presume this function will return anything you want
  // WARNING: Should you get an error along the lines of: "Cannot read property 'match' of undefined" when developing storybook stories, and you traced this here,
  // then you need to make sure to pass 'DomRouterDecorator' as the last decorator of the story.
  const params = useParams<P>();
  const { voyageUuid } = useParams<UrlParams>();
  const { mlvUuid } = useParams<UrlParams>();
  const {
    voyageUuid: voyageUuidFromUrl,
    vesselUuid: vesselUuidFromUrl,
    routeUuid,
  } = useWayfinderUrlUuids();

  const history = useHistory();

  const { recentVoyageUuid } = useRecentVoyage();
  const showFleetView = useFleetViewVisibility();

  const [query, setQuery] = useQueryParams({
    routesToCompare: StringParam,
    plotQuantity: withDefault(StringParam, plotQuantityDefault),
    simulatedHistoricalTime: withDefault(
      StringParam,
      simulatedHistoricalTimeDefault
    ),
    fleetViewTab: StringParam,
    rpmAdherenceUuid: StringParam,
  });
  const queryRef = useRef(query);
  queryRef.current = query;

  const [weatherQuery, setWeatherQuery] = useQueryParams({
    showTropicalStorms: withDefault(
      BooleanParam,
      weatherMapLayerShowTropicalStormsDefault
    ),
    showNauticalCharts: withDefault(
      BooleanParam,
      weatherMapLayerShowNauticalChartsDefault
    ),
    raster: withDefault(StringParam, weatherMapLayerRasterDefault),
    vector: withDefault(StringParam, weatherMapLayerVectorDefault),
    showPressure: withDefault(BooleanParam, weatherMapLayerPressureDefault),
    showIceLayers: withDefault(
      BooleanParam,
      weatherMapLayerShowIceLayersDefault
    ),
  });

  const routeUuidsToCompare = useMemo(
    () =>
      query.routesToCompare
        ? query.routesToCompare.split(",").map((s) => s.trim())
        : [],
    [query.routesToCompare]
  );

  const getUrl = useCallback(
    <T extends WayfinderUrlParam<WayfinderUrl>>(
      path: WayfinderUrl,
      options?: { params?: T; replace?: boolean }
    ) => {
      let url = "/";
      let newVoyageId;

      const currentVoyageUuid =
        (options?.params as WayfinderUrlParam<"voyage">)?.voyageUuid ?? // FIXME coercion hack
        voyageUuidFromUrl ??
        recentVoyageUuid;
      const currentVesselUuid =
        (options?.params as WayfinderUrlParam<"voyage">)?.vesselUuid ?? // FIXME coercion hack
        vesselUuidFromUrl;
      const voyageRoot = `/vessel/${currentVesselUuid}/voyage/`;

      switch (path) {
        case "all-voyages":
          newVoyageId = options?.params
            ? (options.params as WayfinderUrlParam<"voyage">)["voyageUuid"] // FIXME coercion hack
            : currentVoyageUuid;
          url = `/vessel/${currentVesselUuid}/voyages`;
          break;
        case "new-voyage":
          url = `/vessel/${currentVesselUuid}/new-voyage`;
          break;
        case "edit-voyage":
          url = `${voyageRoot}${currentVoyageUuid}/edit`;
          break;
        case "route-import":
          url = `${voyageRoot}${currentVoyageUuid}/import`;
          break;
        // we are falling through these as they are all handled the same.
        case "optimization":
        case "optimization-history":
        case "dashboard":
        case "seakeeping":
        case "area-constraints":
        case "layers":
          newVoyageId = options?.params
            ? (options.params as WayfinderUrlParam<"layers">)["voyageUuid"]
            : currentVoyageUuid;
          url = `${voyageRoot}${newVoyageId}/${path}`;
          break;
        case "voyage":
          newVoyageId = options?.params
            ? (options.params as WayfinderUrlParam<"voyage">)["voyageUuid"]
            : undefined;
          url = `${voyageRoot}${newVoyageId ?? currentVoyageUuid}`;
          break;
        case "voyage-print":
          {
            const voyageUuid = options?.params
              ? (options.params as WayfinderUrlParam<"voyage-print">)[
                  "voyageUuid"
                ]
              : undefined;
            url = `${voyageRoot}${
              voyageUuid ?? currentVoyageUuid
            }${PRINT_PATH}`;
          }
          break;
        case "routes":
          {
            const voyageUuid = options?.params
              ? (options.params as WayfinderUrlParam<"routes">)["voyageUuid"]
              : undefined;
            url = `${voyageRoot}${voyageUuid ?? currentVoyageUuid}/routes`;
          }
          break;
        case "route-detail":
          const routeId = options?.params
            ? (options?.params as WayfinderUrlParam<"route-detail">)[
                "routeUuid"
              ]
            : "";
          url = `${voyageRoot}${currentVoyageUuid}/routes/${routeId}`;
          break;

        case "route-edit":
          const routeUuid = options?.params
            ? (options?.params as WayfinderUrlParam<"route-detail">)[
                "routeUuid"
              ]
            : "";
          url = `${voyageRoot}${currentVoyageUuid}/routes/${routeUuid}/edit`;
          break;

        case "vessel":
          {
            const vesselUuid = options?.params
              ? (options?.params as WayfinderUrlParam<"vessel">)["vesselUuid"]
              : vesselUuidFromUrl;
            url = `/vessel/${vesselUuid}`;
          }
          break;
        case "vessel-adherence":
          {
            const vesselUuid = options?.params
              ? (options?.params as WayfinderUrlParam<"vessel-adherence">)[
                  "vesselUuid"
                ]
              : vesselUuidFromUrl;
            url = `/vessel/${vesselUuid}/adherence`;
          }
          break;
        case "vessel-performance":
          {
            const vesselUuid = options?.params
              ? (options?.params as WayfinderUrlParam<"vessel-performance">)[
                  "vesselUuid"
                ]
              : undefined;
            url = `/vessel/${vesselUuid}/performance`;
          }
          break;
        case "vessel-noons":
          {
            const vesselUuid = options?.params
              ? (options?.params as WayfinderUrlParam<"vessel-noons">)[
                  "vesselUuid"
                ]
              : undefined;
            url = `/vessel/${vesselUuid}/noons`;
          }
          break;
        case "vessel-reports":
          {
            const vesselUuid = options?.params
              ? (options?.params as WayfinderUrlParam<"vessel-reports">)[
                  "vesselUuid"
                ]
              : undefined;
            url = `/vessel/${vesselUuid}/reports`;
          }
          break;
        case "vessel-voyage-report":
          {
            const vesselUuid = options?.params
              ? (options?.params as WayfinderUrlParam<"vessel-voyage-report">)[
                  "vesselUuid"
                ]
              : undefined;
            const mlvUuid = options?.params
              ? (options?.params as WayfinderUrlParam<"vessel-voyage-report">)[
                  "mlvUuid"
                ]
              : undefined;
            url = `/vessel/${vesselUuid}/reports/${mlvUuid}`;
          }
          break;
        case "fleet": {
          // if no permissions, reroute to /
          if (showFleetView) {
            url = FLEET_VIEW_PATH;
          }
          break;
        }
        case "settings-organization-area-constraints": {
          url = ORGANIZATION_AREA_CONSTRAINT_SETTINGS_PATH;
          break;
        }
        case "settings-global-area-constraints": {
          url = GLOBAL_AREA_CONSTRAINT_SETTINGS_PATH;
          break;
        }
        case "settings": {
          url = SETTINGS_PATH;
          break;
        }
      }
      return `${url}${window.location.search}`;
    },
    [voyageUuidFromUrl, recentVoyageUuid, vesselUuidFromUrl, showFleetView]
  );

  /**
   * Sets the query object with only values that are not equal to their defaults.
   */
  const setManagedQuery = useCallback(
    (
      newQuery: Partial<WayfinderQuery>,
      updateType: UrlUpdateType = "replaceIn"
    ) => {
      let result = { ...queryRef.current };
      if (newQuery.routesToCompare) {
        result = {
          ...result,
          routesToCompare: newQuery.routesToCompare,
        };
      } else if (newQuery.routesToCompare === "") {
        // There is no routes to compare anymore, delete it.
        delete result.routesToCompare;
      }
      if (newQuery.plotQuantity) {
        result = {
          ...result,
          plotQuantity: newQuery.plotQuantity,
        };
      }
      if (newQuery.simulatedHistoricalTime) {
        result = {
          ...result,
          simulatedHistoricalTime: newQuery.simulatedHistoricalTime,
        };
      }

      if (newQuery.rpmAdherenceUuid) {
        result = {
          ...result,
          rpmAdherenceUuid: newQuery.rpmAdherenceUuid,
        };
      } else {
        delete result.rpmAdherenceUuid;
      }

      // the following lines compare the newly created object against the defaults and only sets the properties
      // that do not equal their defaults.
      setQuery(
        {
          routesToCompare: result.routesToCompare ?? undefined,
          plotQuantity:
            result.plotQuantity !== plotQuantityDefault
              ? result.plotQuantity
              : undefined,
          simulatedHistoricalTime:
            result.simulatedHistoricalTime !== simulatedHistoricalTimeDefault
              ? result.simulatedHistoricalTime
              : undefined,
          rpmAdherenceUuid: result.rpmAdherenceUuid,
        },
        updateType
      );
    },
    [setQuery]
  );

  /**
   * Sets the weather layers query object with only values that are not equal to their defaults.
   */
  const setManagedWeatherQuery = useCallback(
    (
      newQuery: Partial<WayfinderWeatherQuery>,
      updateType: UrlUpdateType = "replaceIn"
    ) => {
      let result = { ...weatherQuery };
      if (
        newQuery.raster ||
        newQuery.vector ||
        newQuery.showSpotters !== undefined ||
        newQuery.showPressure !== undefined ||
        newQuery.showTropicalStorms !== undefined ||
        newQuery.showNauticalCharts !== undefined ||
        newQuery.showIceLayers !== undefined
      ) {
        result = {
          ...result,
          raster: newQuery.raster || weatherMapLayerRasterDefault,
          vector: newQuery.vector || weatherMapLayerVectorDefault,
          showPressure:
            newQuery.showPressure !== undefined
              ? newQuery.showPressure
              : weatherMapLayerPressureDefault,
          showTropicalStorms:
            newQuery.showTropicalStorms !== undefined
              ? newQuery.showTropicalStorms
              : weatherMapLayerShowTropicalStormsDefault,
          showNauticalCharts:
            newQuery.showNauticalCharts !== undefined
              ? newQuery.showNauticalCharts
              : weatherMapLayerShowNauticalChartsDefault,
          showIceLayers:
            newQuery.showIceLayers !== undefined
              ? newQuery.showIceLayers
              : weatherMapLayerShowIceLayersDefault,
        };
      }
      // the following lines compare the newly created object against the defaults and only sets the properties
      // that do not equal their defaults.
      setWeatherQuery(
        {
          raster:
            result.raster !== weatherMapLayerRasterDefault
              ? (result.raster as string)
              : undefined,
          vector:
            result.vector !== weatherMapLayerVectorDefault
              ? (result.vector as string)
              : undefined,
          showPressure:
            result.showPressure !== weatherMapLayerPressureDefault
              ? result.showPressure
              : undefined,
          showTropicalStorms:
            result.showTropicalStorms !==
            weatherMapLayerShowTropicalStormsDefault
              ? result.showTropicalStorms
              : undefined,
          showNauticalCharts:
            result.showNauticalCharts !==
            weatherMapLayerShowNauticalChartsDefault
              ? result.showNauticalCharts
              : undefined,
          showIceLayers:
            result.showIceLayers !== weatherMapLayerShowIceLayersDefault
              ? result.showIceLayers
              : undefined,
        },
        updateType
      );
    },
    [weatherQuery, setWeatherQuery]
  );

  const setRoutesToCompare = useCallback(
    (newRouteUuids: string[]) => {
      if (query.routesToCompare !== newRouteUuids.join(",")) {
        let routesToCompare: string | undefined = undefined;
        if (newRouteUuids.length) {
          routesToCompare = uniq(newRouteUuids).join(",");
        } else {
          // remove the routesToCompare
          routesToCompare = "";
        }
        setManagedQuery({ routesToCompare });
      }
    },
    [query.routesToCompare, setManagedQuery]
  );

  const setSimulatedHistoricalTime = useCallback(
    (simulatedHistoricalTime: string) =>
      setManagedQuery({ simulatedHistoricalTime }),
    [setManagedQuery]
  );

  const setRpmAdherenceUuid = useCallback(
    (rpmAdherenceUuid: string | undefined) =>
      setManagedQuery({ rpmAdherenceUuid }),
    [setManagedQuery]
  );

  const setWeatherMapLayers = useCallback(
    (newMapLayers: WeatherMapLayers) => {
      setManagedWeatherQuery({
        ...newMapLayers,
      });
    },
    [setManagedWeatherQuery]
  );

  const setUrl = useCallback(
    <T extends WayfinderUrlParam<WayfinderUrl>>(
      path: WayfinderUrl,
      options?: { params?: T; replace?: boolean }
    ) => {
      const navigate = options?.replace ? history.replace : history.push;
      const paramsAsVoyageActionParams = options?.params as
        | WayfinderUrlParam<"voyage">
        | undefined;
      // Set the routes to compare _before_ getting the URL, otherwise `getUrl` will overwrite our
      // new params with whatever was there before.
      if (paramsAsVoyageActionParams?.routesToCompare) {
        const routesToCompare = paramsAsVoyageActionParams?.routesToCompare;
        setRoutesToCompare(routesToCompare);
      }

      const url = getUrl(path, options);
      if (url) {
        navigate(url);
      }
    },
    [history.replace, history.push, getUrl, setRoutesToCompare]
  );

  const setWeatherPlotQuantity = useCallback(
    (newQuantity: PlotQuantities) => {
      setQuery({ plotQuantity: newQuantity });
    },
    [setQuery]
  );

  const simulatedHistoricalTime = useMemo(
    () =>
      query.simulatedHistoricalTime !== ""
        ? new Date(query.simulatedHistoricalTime)
        : undefined,
    [query.simulatedHistoricalTime]
  );

  const fleetViewTab = useMemo(() => {
    if (FLEET_VIEW_TABS.find((tab) => tab === query.fleetViewTab)) {
      return query.fleetViewTab as FleetViewTab;
    }
    return undefined;
  }, [query.fleetViewTab]);

  const setFleetViewTab = useCallback(
    (tab: FleetViewTab) => setQuery({ fleetViewTab: tab }),
    [setQuery]
  );

  return useMemo(
    () => ({
      voyageUuid,
      vesselUuid: vesselUuidFromUrl,
      mlvUuid,
      routeUuid,
      routeUuidsToCompare,
      ...params,

      weatherMapLayers: {
        raster: weatherQuery.raster as RasterWeatherLayer,
        vector: weatherQuery.vector as VectorWeatherLayer,
        showPressure: weatherQuery.showPressure,
        showTropicalStorms: weatherQuery.showTropicalStorms,
        showNauticalCharts: weatherQuery.showNauticalCharts,
        showIceLayers: weatherQuery.showIceLayers,
      },
      weatherPlotQuantity: query.plotQuantity as PlotQuantities,
      simulatedHistoricalTime,
      getUrl,
      setUrl,
      setRoutesToCompare,
      setWeatherMapLayers,
      setWeatherPlotQuantity,
      setSimulatedHistoricalTime,
      fleetViewTab,
      setFleetViewTab,
      setRpmAdherenceUuid,
      rpmAdherenceUuid: query.rpmAdherenceUuid ?? undefined,
    }),
    [
      voyageUuid,
      vesselUuidFromUrl,
      mlvUuid,
      routeUuid,
      routeUuidsToCompare,
      params,
      weatherQuery.raster,
      weatherQuery.vector,
      weatherQuery.showPressure,
      weatherQuery.showTropicalStorms,
      weatherQuery.showNauticalCharts,
      weatherQuery.showIceLayers,
      query.plotQuantity,
      query.rpmAdherenceUuid,
      simulatedHistoricalTime,
      getUrl,
      setUrl,
      setRoutesToCompare,
      setWeatherMapLayers,
      setWeatherPlotQuantity,
      setSimulatedHistoricalTime,
      fleetViewTab,
      setFleetViewTab,
      setRpmAdherenceUuid,
    ]
  );
};

export { useWayfinderUrl };
