import tilecover from "@mapbox/tile-cover";
import { Polygon, MultiPolygon } from "@turf/helpers";
import { isNil, uniq } from "lodash";
import { Position } from "@turf/helpers";
import { MapBounds } from "shared-types";
import { getRoiGeometryFromBounds } from "screens/VoyageScreen/use-map-roi-updater";
import { StatefulForecastProvider } from "@sofarocean/weather-cubes";
import { CUBE_QUADKEY_ZOOM_LEVEL } from "@sofarocean/weather-cubes/build/WeatherProvider";
import { RouteScoreOptions } from "../components/sidebar/RouteSummary/use-route-score-options";
import { WeatherQuantities } from "../config";
import { CalculatedScheduleElement } from "../shared-types/RouteTypes";
import { AbsoluteRouteScoreMetrics } from "./routeSummary";
import { isNumber } from "./units";
import { normalizeLongitude } from "./geometry";

export const GLOBAL_MAP_BOUNDS: MapBounds = {
  bounds: [-180, -80, 180, 80],
};
export const GLOBAL_ROI = getRoiGeometryFromBounds(GLOBAL_MAP_BOUNDS.bounds);

// normalizes to a 0-1 range, clips at min and max
const normalizeWeatherScore = (
  value: number,
  config: { magnitudeMax: number; magnitudeMin: number }
) => {
  return Math.min(
    Math.max(
      (value - config.magnitudeMin) /
        (config.magnitudeMax - config.magnitudeMin),
      0
    ),
    1
  );
};

export const accumulateAdverseCurrents = (
  score: number | undefined,
  currentFactor: number,
  legTimeElapsedMs: number,
  options: RouteScoreOptions
) =>
  isNumber(score)
    ? score +
      legTimeElapsedMs *
        (options.useAbsoluteLinearCurrentScores
          ? -currentFactor > options.adverseCurrentMin
            ? -currentFactor
            : 0
          : Math.pow(
              normalizeWeatherScore(
                -1 * Math.min(currentFactor, -options.adverseCurrentMin),
                {
                  magnitudeMin: options.adverseCurrentMin,
                  magnitudeMax: options.adverseCurrentMax,
                }
              ),
              2
            ))
    : undefined;

export const accumulateFavorableCurrents = (
  score: number | undefined,
  currentFactor: number,
  legTimeElapsedMs: number,
  options: RouteScoreOptions
) =>
  isNumber(score)
    ? score +
      legTimeElapsedMs *
        (options.useAbsoluteLinearCurrentScores
          ? currentFactor > options.favorableCurrentMin
            ? currentFactor
            : 0
          : Math.pow(
              normalizeWeatherScore(
                Math.max(currentFactor, options.favorableCurrentMin),
                {
                  magnitudeMin: options.favorableCurrentMin,
                  magnitudeMax: options.favorableCurrentMax,
                }
              ),
              2
            ))
    : undefined;

export const accumulateMaxWaveHeights = (
  score: number | undefined,
  significantWaveHeight: number,
  legTimeElapsedMs: number,
  options: RouteScoreOptions
) =>
  isNumber(score)
    ? score +
      legTimeElapsedMs *
        Math.pow(
          normalizeWeatherScore(significantWaveHeight, {
            magnitudeMin: options.adverseWaveHeightMin,
            magnitudeMax: options.adverseWaveHeightMax,
          }),
          2
        )
    : undefined;

export const accumulateMaxWindSpeeds = (
  score: number | undefined,
  windSpeed: number,
  legTimeElapsedMs: number,
  options: RouteScoreOptions
) =>
  isNumber(score)
    ? score +
      legTimeElapsedMs *
        Math.pow(
          normalizeWeatherScore(windSpeed, {
            magnitudeMin: options.adverseWindSpeedMin,
            magnitudeMax: options.adverseWindSpeedMax,
          }),
          2
        )
    : undefined;

export const accumulateFavorableWindSpeeds = (
  score: number | undefined,
  windSpeed: number,
  legTimeElapsedMs: number,
  options: RouteScoreOptions
) =>
  isNumber(score)
    ? score +
      (windSpeed > options.favorableWindSpeedMax
        ? 0
        : legTimeElapsedMs *
          (1 - // invert parabola so changes close to 0 matter less than changes near threshold
            Math.pow(
              normalizeWeatherScore(windSpeed, {
                magnitudeMin: 0,
                magnitudeMax: options.favorableWindSpeedMax,
              }),
              2
            )))
    : undefined;

export const accumulateFavorableWaveHeights = (
  score: number | undefined,
  significantWaveHeight: number,
  legTimeElapsedMs: number,
  options: RouteScoreOptions
) =>
  isNumber(score)
    ? score +
      (significantWaveHeight > options.favorableWaveHeightMax
        ? 0
        : legTimeElapsedMs *
          (1 - // invert parabola so changes close to 0 matter less than changes near threshold
            Math.pow(
              normalizeWeatherScore(significantWaveHeight, {
                magnitudeMin: 0,
                magnitudeMax: options.favorableWaveHeightMax,
              }),
              2
            )))
    : undefined;

/**
 * Accumulate the scores that drive the route appeal language
 * @param prevScheduleElement
 * @param scheduleElement
 * @param simulatedRoute
 * @param scores
 * @returns
 */
export function accumulateRouteAppealScores(
  scheduleElement: CalculatedScheduleElement,
  legTimeElapsedMs: number,
  options: RouteScoreOptions,
  scores: AbsoluteRouteScoreMetrics
) {
  // sum the weather scores
  const {
    windSpeed,
    extensions: {
      currentFactor = undefined,
      significantWaveHeight = undefined,
    } = {},
  } = scheduleElement ?? {};

  if (isNumber(currentFactor)) {
    // accumulate the score
    scores.adverseCurrents = accumulateAdverseCurrents(
      scores.adverseCurrents,
      currentFactor,
      legTimeElapsedMs,
      options
    );
    scores.favorableCurrents = accumulateFavorableCurrents(
      scores.favorableCurrents,
      currentFactor,
      legTimeElapsedMs,
      options
    );
  }

  // compute wave and weather based on scalar values. we can factor in direction if needed as well
  if (isNumber(significantWaveHeight)) {
    scores.maxWaveHeights = accumulateMaxWaveHeights(
      scores.maxWaveHeights,
      significantWaveHeight,
      legTimeElapsedMs,
      options
    );
    scores.favorableWaveHeights = accumulateFavorableWaveHeights(
      scores.favorableWaveHeights,
      significantWaveHeight,
      legTimeElapsedMs,
      options
    );
  }
  if (isNumber(windSpeed)) {
    scores.maxWindSpeeds = accumulateMaxWindSpeeds(
      scores.maxWindSpeeds,
      windSpeed,
      legTimeElapsedMs,
      options
    );
    scores.favorableWindSpeeds = accumulateFavorableWindSpeeds(
      scores.favorableWindSpeeds,
      windSpeed,
      legTimeElapsedMs,
      options
    );
  }
}

// Only these scores will be normalized
// UPDATE THE FUNCTION BELOW IF THIS CHANGES
type SupportedRouteScores = {
  adverseCurrents: number | undefined;
  favorableCurrents: number | undefined;
  maxWaveHeights: number | undefined;
  maxWindSpeeds: number | undefined;
  favorableWaveHeights: number | undefined;
  favorableWindSpeeds: number | undefined;
};

export function normalizeRouteAppealScores(
  forecastEndDates: Record<WeatherQuantities, Date>,
  time: Date,
  options: RouteScoreOptions,
  scores: SupportedRouteScores
) {
  const timeEpoch = time.getTime();
  // normalize the data by dividing by the time from now until the end of each forecast
  const currentsDurationMs = forecastEndDates.currents?.getTime() - timeEpoch;
  const windsDurationMs = forecastEndDates.wind?.getTime() - timeEpoch;
  const wavesDurationMs = forecastEndDates.combinedWaves?.getTime() - timeEpoch;
  if (isNumber(scores.adverseCurrents))
    if (options.useAbsoluteLinearCurrentScores)
      // convert from ktms to kthours
      scores.adverseCurrents /= 60 * 60 * 1000;
    else scores.adverseCurrents /= currentsDurationMs;

  if (isNumber(scores.favorableCurrents))
    if (options.useAbsoluteLinearCurrentScores)
      // convert from ktms to kthours
      scores.favorableCurrents /= 60 * 60 * 1000;
    else scores.favorableCurrents /= currentsDurationMs;

  if (isNumber(scores.maxWaveHeights)) scores.maxWaveHeights /= wavesDurationMs;

  if (isNumber(scores.maxWindSpeeds)) scores.maxWindSpeeds /= windsDurationMs;

  if (isNumber(scores.favorableWaveHeights))
    scores.favorableWaveHeights /= wavesDurationMs;

  if (isNumber(scores.favorableWindSpeeds))
    scores.favorableWindSpeeds /= windsDurationMs;
}

export const removeAntimeridianDiscontinuityFromCoordinates = (
  coordinates: Position[][]
) => {
  coordinates.forEach((coords: Position[]) => {
    if (typeof coords[0][0] === "number")
      coords.forEach((position: Position, index: number, array: Position[]) => {
        const firstPosition = array[0];
        if (
          typeof position[0] === "number" &&
          typeof firstPosition[0] === "number"
        ) {
          position[0] =
            normalizeLongitude(position[0] - firstPosition[0]) +
            firstPosition[0];
        }
      });
  });
};

export const removeAntimeridianDiscontinuityFromGeometry = (
  geometry: Polygon | MultiPolygon
) => {
  if (geometry.type === "Polygon")
    removeAntimeridianDiscontinuityFromCoordinates(geometry.coordinates);
  else if (geometry.type === "MultiPolygon")
    geometry.coordinates.forEach((c) =>
      removeAntimeridianDiscontinuityFromCoordinates(c)
    );
};

export const windSpeedKtsToBeaufort = (speedKts: number): number | null => {
  if (isNil(speedKts) || isNaN(speedKts) || speedKts === Infinity) return null;

  if (speedKts < 1) return 0;
  else if (speedKts <= 3) return 1;
  else if (speedKts <= 6) return 2;
  else if (speedKts <= 10) return 3;
  else if (speedKts <= 16) return 4;
  else if (speedKts <= 21) return 5;
  else if (speedKts <= 27) return 6;
  else if (speedKts <= 33) return 7;
  else if (speedKts <= 40) return 8;
  else if (speedKts <= 47) return 9;
  else if (speedKts <= 55) return 10;
  else if (speedKts <= 63) return 11;
  else return 12;
};

/**
 * Gets the quadkeys covering a shape on the map
 * @param regionOfInterest
 * @returns
 */
export const getRegionOfInterestQuadkeys = (
  regionOfInterest: Polygon | MultiPolygon
): string[] => {
  const clippedRegionOfInterest = StatefulForecastProvider.clipRegionOfInterest(
    regionOfInterest
  );
  // Calculate needed quadkey indexes based on region of interest
  const quadkeys = tilecover
    .indexes(clippedRegionOfInterest, {
      min_zoom: CUBE_QUADKEY_ZOOM_LEVEL,
      max_zoom: CUBE_QUADKEY_ZOOM_LEVEL,
    })
    .filter((k) => k !== "");
  return uniq(quadkeys);
};
