import { VoyageStatusV2 } from "@sofarocean/wayfinder-typescript-client";
import { Store } from "idb-keyval";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocalPersistentState } from "shared-hooks/usePersistentState";
import {
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParam,
} from "use-query-params";
import config, {
  devWayfinderBaseUrl,
  localWayfinderBaseUrl,
  productionWayfinderBaseUrl,
  stagingWayfinderBaseUrl,
} from "../../config";

/** Type describing a single application setting */
type AppSetting<TValue> = {
  currentValue?: TValue;
  description?: string;
  defaultValue: TValue;
  displayName: string;
  category?: string;
} & (
  | {
      /** Any setting can be rendered as JSON as a fallback */
      controlType: "json";
    }
  | (TValue extends boolean
      ? { controlType: "switch" }
      : TValue extends number
      ? {
          controlType: "number";
          minimum?: number;
          maximum?: number;
          step?: number;
        }
      : TValue extends string
      ?
          | { controlType: "textfield" }
          | {
              controlType: "select";
              options: { value: TValue; label?: string }[];
            }
      : { controlType: "json" })
);

type AppSettingDefinitions<T extends Record<string, any>> = {
  [P in keyof T]: AppSetting<T[P]>;
};

/** The types of the app settings we use in Tell Tale
 *
 * Example types for configurations in comments below:
 *
 * textExample: string;
 * numberExample: number;
 * selectExample: string;
 */
type WayfinderAppSettingTypes = {
  scrubberThrottleMs: number;
  showPolarDiagramDebugData: boolean;
  forceRollAngleVisibility: boolean;
  enablePrint: boolean;
  staleSpotterDataAgeMs: number;
  recentVoyageUuid: string;
  enableManageArbitraryRoutes: boolean;
  allowImportRouteEtaInPast: boolean;
  enableFurunoSimpleImport: boolean;
  enableJsonRouteImport: boolean;
  enableTotemCsvExport: boolean;
  enableLegacyRtzXmlFormatting: boolean;
  useFirstRouteAsComparisonBasis: boolean;
  preserveRouteDurationsOnImportForm: boolean;
  deriveTimingsFromImportedRouteSpeeds: boolean;
  showRouteScoreDebugger: boolean;
  enableSafetyWarningDebugOutput: boolean;
  includeCalculatedScheduleInRtzExports: boolean;
  showDotsForConeOfUncertainty: boolean;
  useMockTropicalStorm: boolean;
  showTropicalStormWindRadii: boolean;
  hideTropicalStormTracksPastCurrentSimTime: boolean;
  showRouterToolBox: boolean;

  // Route appeal
  favorableWaveHeightMax: number;
  favorableWindSpeedMax: number;
  adverseWaveHeightMin: number;
  adverseWaveHeightMax: number;
  adverseWindSpeedMin: number;
  adverseWindSpeedMax: number;
  favorableCurrentMin: number;
  favorableCurrentMax: number;
  adverseCurrentMin: number;
  adverseCurrentMax: number;
  useAbsoluteLinearCurrentScores: boolean;
  absoluteCurrentScoreThreshold: number;
  minRatioForReportingLargerRouteScore: number;
  maxRatioForReportingSmallerRouteScore: number;
  trivialComparisonBasisThreshold: number;
  shorterDistanceReportingMinimumNM: number;
  earlierArrivalReportingMinimumHr: number;
  lowerConsumptionReportingMinimumMT: number;

  // C-MAP raster layer
  cmapClientId: string;
  cmapClientSecret: string;
  cmapOpacity: number;

  // Fleet view and optimization
  showFleetViewToAllUsers: boolean;
  showAllVesselsInFleetView: boolean;

  voyageStatusOverride: string;
  activeRouteUuidOverride: string;
  apiUrlOverride: string;
  enableLocalSessionCloning: boolean;
  showNauticalChartsByDefault: boolean;
  currentVesselLongitudeOverride: string;

  fleetOrgFilterString: string;
  hideSidebar: boolean;

  favoriteVessels: string;
  favoriteVesselsWithGroups: string;
  fleetViewVesselListMode: string;
  fleetViewSelectedVessels: string;
  fleetViewSelectedVesselsWithGroups: string;
};

export type WayfinderAppSettingName = keyof WayfinderAppSettingTypes;

/** The default configuration and categorization of settings in our app
 *
 * Example configurations:
 *
 * textExample: {
 *   displayName: "Example text setting",
 *   category: "Examples",
 *   defaultValue: "This is the default value",
 *   controlType: "textfield",
 * },
 * numberExample: {
 *   displayName: "Example number setting",
 *   category: "Examples",
 *   defaultValue: 100,
 *   controlType: "number",
 *   minimum: 0,
 *   maximum: 100,
 *   step: 5,
 * },
 * selectExample: {
 *   displayName: "Example select setting",
 *   category: "Examples",
 *   defaultValue: "Alpha",
 *   controlType: "select",
 *   options: [
 *     { value: "Alpha" },
 *     { value: "Bravo" },
 *     { value: "Charlie" },
 *     { value: "Delta" },
 *     { value: "Echo" },
 *     { value: "Foxtrot" },
 *     { value: "Golf" },
 *     { value: "Hotel" },
 *   ],
 * },
 *
 */
export const wayfinderAppSettingDefinitions: AppSettingDefinitions<WayfinderAppSettingTypes> = {
  scrubberThrottleMs: {
    displayName: "Scrubber throttle",
    description: "Scrubber throttle",
    category: "Admin",
    // Scrubbing the timeline generates mouse events between 3 and 100 ms apart in testing,
    // but we do not need them any faster than every 40 ms (25HZ)
    // https://www.techsmith.com/blog/frame-rate-beginners-guide/#:~:text=they're%20used.-,24fps,produced%20and%20displayed%20at%2024fps.
    defaultValue: 40,
    controlType: "number",
    minimum: 0,
    step: 1,
  },
  showPolarDiagramDebugData: {
    displayName: "Show debug data on polar plot",
    description:
      "Show cpu-intensive raster data on the polar plot for debugging",
    category: "Admin",
    defaultValue: false,
    controlType: "switch",
  },
  forceRollAngleVisibility: {
    displayName: "Force roll angle visibility",
    description: "Force roll angle to show on any vessel or org",
    category: "Admin",
    defaultValue: false,
    controlType: "switch",
  },
  enablePrint: {
    displayName: "Enable print",
    description: "Enable printing out a summary of a voyage",
    category: "Admin",
    defaultValue: false,
    controlType: "switch",
  },
  staleSpotterDataAgeMs: {
    displayName: "Stale Spotter Age (msec)",
    description: "Older data is hidden from user",
    category: "Admin",
    defaultValue: config.staleSpotterDataAgeMs,
    controlType: "number",
    minimum: 0,
    step: 1000 * 60,
  },
  recentVoyageUuid: {
    displayName: "Recent Voyage Uuid",
    description:
      "This is the voyage Uuid that will load at startup unless overridden by a direct URL to another voyage",
    category: "Startup Configuration",
    defaultValue: "",
    controlType: "textfield",
  },
  enableManageArbitraryRoutes: {
    displayName: "Enable Following Arbitrary Routes",
    description: "Should we show follow/delete buttons on arbitrary routes?",
    category: "Feature Flags",
    defaultValue: false,
    controlType: "switch",
  },
  allowImportRouteEtaInPast: {
    displayName: "Allow importing routes with an ETA in the past",
    description: "This helps with testing",
    category: "Feature Flags",
    defaultValue: false,
    controlType: "switch",
  },
  enableFurunoSimpleImport: {
    displayName: "Furuno Simple Import",
    description: "Enable importing Furuno Simple routes?",
    category: "Feature Flags",
    defaultValue: false,
    controlType: "switch",
  },
  enableJsonRouteImport: {
    displayName: "JSON route Import",
    description: "Enable importing JSON routes?",
    category: "Feature Flags",
    defaultValue: false,
    controlType: "switch",
  },
  enableTotemCsvExport: {
    displayName: "Totem/Navig8 CSV Export",
    description: "Enable exporting Totem/Navig8 csv routes?",
    category: "Feature Flags",
    defaultValue: false,
    controlType: "switch",
  },
  enableLegacyRtzXmlFormatting: {
    displayName: "Legacy RTZ XML Formatting",
    description: "Remove tweaks made on 2/18/22 to rtz xml export format",
    category: "Feature Flags",
    defaultValue: false,
    controlType: "switch",
  },
  useFirstRouteAsComparisonBasis: {
    displayName: `Use first route in list as comparison basis if the active route is not in the comparison`,
    description:
      "Rather than using the active route, use the first route in the list as the comparison basis when the active route is absent",
    category: "Feature Flags",
    defaultValue: false,
    controlType: "switch",
  },
  preserveRouteDurationsOnImportForm: {
    displayName: `Preserve route durations on import form`,
    description:
      "If the imported route has timing information, make it easier to change the etd and eta by the same amount and preserve the existing route duration and speeds.",
    category: "Feature Flags",
    defaultValue: false,
    controlType: "switch",
  },
  deriveTimingsFromImportedRouteSpeeds: {
    displayName: `Preserve route timings implied by speeds in a route missing times`,
    description:
      "Compute times based on speeds if an imported route has speeds but no times",
    category: "Feature Flags",
    defaultValue: false,
    controlType: "switch",
  },
  showRouteScoreDebugger: {
    displayName: `Show "Route Scores Debugger" in the sidebar`,
    description:
      "Route score debugger is an internal feature based on route analysis that lets us explore differences between weather along routes",
    category: "Feature Flags",
    defaultValue: false,
    controlType: "switch",
  },
  enableSafetyWarningDebugOutput: {
    displayName:
      "Enable debug output (in the JS console) about the values used to calculate safety warnings",
    category: "Feature Flags",
    defaultValue: false,
    controlType: "switch",
  },
  includeCalculatedScheduleInRtzExports: {
    displayName: "Include Caluculated Schedule in '.RTZ' Exports",
    description:
      "The calculated schedule in wayfinder contains simulator data at 1 hour increments. Should these schedule elements and their waypoints be included in the '.rtz' export?",
    category: "Feature Flags",
    defaultValue: false,
    controlType: "switch",
  },
  showDotsForConeOfUncertainty: {
    displayName: "Show dot pattern for Cone of Uncertainty",
    description:
      "If a Cone of Uncertainty is shown, show it with a dot pattern. Alternatively, show a white translucent area.",
    category: "Feature Flags",
    defaultValue: false,
    controlType: "switch",
  },
  useMockTropicalStorm: {
    displayName: "Use a mock result for tropical storms",
    description:
      "If tropical storms are enabled and this is set to enabled, we use a mock result for tropical storms.",
    category: "Feature Flags",
    defaultValue: false,
    controlType: "switch",
  },
  showTropicalStormWindRadii: {
    displayName: "Show wind radii for tropical storms",
    description: "Show the wind raii in the legend and on the map.",
    category: "Feature Flags",
    defaultValue: false,
    controlType: "switch",
  },
  hideTropicalStormTracksPastCurrentSimTime: {
    displayName: "Hide Tropical Storm Tracks past the current simulator time",
    description:
      "When the timeline scrubber is moved past the last storm position, hide the storm and track.",
    category: "Feature Flags",
    defaultValue: false,
    controlType: "switch",
  },

  showFleetViewToAllUsers: {
    displayName: "Show fleet view to all users",
    description:
      "Show fleet view, accessible in rail, independent of user's organizational role.",
    category: "Fleet View / Optimization",
    defaultValue: false,
    controlType: "switch",
  },

  showAllVesselsInFleetView: {
    displayName: "Always show all vessels",
    description: "Ignores the 'Show in Fleet View' flag in retool.",
    category: "Fleet View / Optimization",
    defaultValue: false,
    controlType: "switch",
  },

  // route appeal
  favorableWaveHeightMax: {
    displayName: "Favorable Weather Wave Height Max",
    description: "Wave heights above this are not considered favorable",
    category: "Route Score Options",
    defaultValue: 2.5,
    controlType: "number",
  },
  favorableWindSpeedMax: {
    displayName: "Favorable Weather Wind Speed Max",
    description: "Wind speeds above this are not considered favorable",
    category: "Route Score Options",
    defaultValue: 11,
    controlType: "number",
  },
  adverseWaveHeightMin: {
    displayName: "Adverse Weather Wave Height Min",
    description: "Wave heights above this are considerd adverse",
    category: "Route Score Options",
    defaultValue: 3.5,
    controlType: "number",
  },
  adverseWaveHeightMax: {
    displayName: "Adverse Weather Wave Height Max",
    description:
      "All wave height values above this get the worst possible score",
    category: "Route Score Options",
    defaultValue: 10,
    controlType: "number",
  },
  adverseWindSpeedMin: {
    displayName: "Adverse Wind Speed Min",
    description: "Wind speeds above this are considered adverse",
    category: "Route Score Options",
    defaultValue: 20,
    controlType: "number",
  },
  adverseWindSpeedMax: {
    displayName: "Adverse Wind Speed Max",
    description: "All wind speeds above this get the worst possible score",
    category: "Route Score Options",
    defaultValue: 45,
    controlType: "number",
  },
  favorableCurrentMin: {
    displayName: "Favorable Currents Min",
    description: "Ignore adverse currents under this value",
    category: "Route Score Options",
    defaultValue: 0,
    controlType: "number",
  },
  favorableCurrentMax: {
    displayName: "Favorable Currents Max",
    description: "All values above this get the best possible score",
    category: "Route Score Options",
    defaultValue: 0.8,
    controlType: "number",
  },
  adverseCurrentMin: {
    displayName: "Adverse Currents Min",
    description: "Ignore adverse currents under this value",
    category: "Route Score Options",
    defaultValue: 0.15,
    controlType: "number",
  },
  adverseCurrentMax: {
    displayName: "Adverse Currents Max",
    description: "All values above this get the worst possible score",
    category: "Route Score Options",
    defaultValue: 0.8,
    controlType: "number",
  },
  useAbsoluteLinearCurrentScores: {
    displayName: "Use absolute linear current scores",
    description:
      "Compare currents based on values in 'knot hours', not ratios of squared scores",
    category: "Route Score Options",
    defaultValue: true,
    controlType: "switch",
  },
  absoluteCurrentScoreThreshold: {
    displayName:
      "Currents threshold in 'knot hours', above which the difference between two routes' currents is considered significant",
    description:
      "absoluteCurrentScoreThreshold -- only effective when useAbsoluteLinearCurrentScores is true ",
    category: "Route Score Options",
    defaultValue: 4,
    controlType: "number",
  },
  minRatioForReportingLargerRouteScore: {
    displayName: "Min ratio for reporting larger scores",
    description:
      "The score ratio must be above this value for the difference to be considered significant",
    category: "Route Score Options",
    defaultValue: 1.5,
    controlType: "number",
  },
  maxRatioForReportingSmallerRouteScore: {
    displayName: "Max ratio for reporting smaller scores",
    description:
      "The score ratio must be below this value for the difference to be considered significant",
    category: "Route Score Options",
    defaultValue: 0.75,
    controlType: "number",
  },
  trivialComparisonBasisThreshold: {
    displayName: "Trivial comparison basis maximum",
    description:
      "No ratio will be computed when the comparison basis score is less than this value",
    category: "Route Score Options",
    defaultValue: 0.000005,
    controlType: "number",
  },
  shorterDistanceReportingMinimumNM: {
    displayName: "Shorter distance reporting minimum (NM)",
    description:
      "Distance difference must exceed this value to be reported in route appeal",
    category: "Route Score Options",
    defaultValue: 1,
    controlType: "number",
  },
  earlierArrivalReportingMinimumHr: {
    displayName: "Earlier arrival reporting minimum (hours)",
    description:
      "Time difference must exceed this value to be reported in route appeal",
    category: "Route Score Options",
    defaultValue: 1,
    controlType: "number",
  },
  lowerConsumptionReportingMinimumMT: {
    displayName: "Lower consumption reporting minimum (MT)",
    description:
      "Fuel difference must exceed this value to be reported in route appeal",
    category: "Route Score Options",
    defaultValue: 1,
    controlType: "number",
  },
  cmapClientId: {
    displayName: "Client ID",
    category: "C-MAP Nautical charts",
    defaultValue: "",
    controlType: "textfield",
  },
  cmapClientSecret: {
    displayName: "Client Secret",
    category: "C-MAP Nautical charts",
    defaultValue: "",
    controlType: "textfield",
  },
  cmapOpacity: {
    // also controlled by a slider in the map layers sidebar
    displayName: "Opacity",
    description: "Opacity of the Nautical chart layer",
    category: "C-MAP Nautical charts",
    controlType: "number",
    defaultValue: 0.5,
    minimum: 0,
    maximum: 1,
    step: 0.05,
  },
  voyageStatusOverride: {
    displayName: "Voyage Status Override",
    category: "Feature Flags",
    defaultValue: "",
    controlType: "select",
    options: [
      { value: "" },
      { value: VoyageStatusV2.Archived },
      { value: VoyageStatusV2.Arrived },
      { value: VoyageStatusV2.PendingData },
      { value: VoyageStatusV2.RoutingUnderway },
      { value: VoyageStatusV2.StandbyForArrival },
      { value: VoyageStatusV2.StandbyForDeparture },
      { value: VoyageStatusV2.StandbyUnderway },
    ],
  },
  activeRouteUuidOverride: {
    displayName: "Active Route Uuid Override (development environment only)",
    category: "Feature Flags",
    defaultValue: "",
    controlType: "textfield",
  },
  apiUrlOverride: {
    displayName: "API URL Override (development environment only)",
    category: "Feature Flags",
    defaultValue: "",
    controlType: "select",
    options: [
      { value: "" },
      { value: localWayfinderBaseUrl, label: "local" },
      { value: devWayfinderBaseUrl, label: "dev" },
      { value: stagingWayfinderBaseUrl, label: "staging" },
      { value: productionWayfinderBaseUrl, label: "production" },
    ],
  },
  enableLocalSessionCloning: {
    displayName: "Enable local session cloning",
    description:
      "Enable downloading and uploading wayfinder session data for testing. Disables connection to the API.",
    category: "Admin",
    defaultValue: false,
    controlType: "switch",
  },
  currentVesselLongitudeOverride: {
    displayName: "Override current vessel Longitude",
    description: "Helps with mocking a timezone",
    category: "Admin",
    defaultValue: "",
    controlType: "textfield",
  },
  showNauticalChartsByDefault: {
    displayName: "Show Nautical Charts by Default",
    description: "Show Nautical Charts by default on app load",
    category: "Admin",
    defaultValue: false,
    controlType: "switch",
  },
  showRouterToolBox: {
    displayName: "Show Router's tool box",
    description:
      "When true, show the UUID of the routes and Ensemble Op in the Route Summary section of the Voyage Details sidebar, and show more quantities in timeline that helps the route comparison.",
    category: "Admin",
    defaultValue: false,
    controlType: "switch",
  },
  hideSidebar: {
    displayName: "Hide sidebar",
    description: "Keep sidebar collapsed.",
    category: "Admin",
    defaultValue: false,
    controlType: "switch",
  },
  fleetOrgFilterString: {
    displayName: "Fleet org filter string",
    description: "Filter fleet view to just the org matching this string",
    category: "Admin",
    defaultValue: "",
    controlType: "textfield",
  },
  favoriteVessels: {
    displayName: "Favorite Vessels",
    description: "List of favorite vessels set by user in settings",
    category: "Admin",
    defaultValue: "[]",
    controlType: "json",
  },
  favoriteVesselsWithGroups: {
    displayName: "Favorite Vessels (with Vessel Groups)",
    description:
      "List of favorite vessels and vessel groups set by user in settings",
    category: "Admin",
    defaultValue: "{}",
    controlType: "json",
  },
  fleetViewSelectedVessels: {
    displayName: "Selected Vessels",
    description: "List of vessels selected by user in Fleet View",
    category: "Admin",
    defaultValue: "[]",
    controlType: "json",
  },
  fleetViewSelectedVesselsWithGroups: {
    displayName: "Selected Vessels (with Vessel Groups)",
    description: "List of vessels and vessel groups by user in Fleet View",
    category: "Admin",
    defaultValue: "{}",
    controlType: "json",
  },
  fleetViewVesselListMode: {
    displayName: "Fleet View Vessel List Mode",
    description: "Selection of vessel list mode in Fleet View",
    category: "Admin",
    defaultValue: "all",
    controlType: "textfield",
  },
};

const AppSettingsStore = new Store(
  config.appSettingsDatabaseName,
  "application-settings"
);

export default function useAppSetting<
  N extends keyof typeof wayfinderAppSettingDefinitions,
  V extends typeof wayfinderAppSettingDefinitions[N]["defaultValue"]
>(
  name: N
): {
  /** The current value of this setting. */
  value: V;

  /** A callback to set a new value for this setting. */
  setValue: (v: V) => void;

  /** Indicates whether this setting is equal to the default value.
   */
  isDefault: boolean;

  /** Indicates whether this setting has ever been loaded this session (true).
   *  Set to `false` on the initial load of the page before a value has been
   *  read from IndexDB. The `value` is always equal to the config's
   *  `defaultValue` until initialization is complete, which may or may not
   *  be acceptable for your use case.
   */
  isInitialized: boolean;

  /** Indicates when a setting is actively loading a value from IndexDB.
   *  This is typically a very short period of time, but since getting values
   *  is asynchronous it may be useful information for your use case.
   */
  isLoading: boolean;

  /** Indicates whether a setting threw an error when getting or setting its
   *  value in IndexDB. There's not much you can do to recover if so, but it
   *  might be nice to know.
   */
  isError: boolean;

  config: typeof wayfinderAppSettingDefinitions[N];
} {
  const appSettingName = `${name}`;
  const { value, setValue, isLoading, isError } = useLocalPersistentState<V>(
    appSettingName,
    AppSettingsStore
  );

  const { queryParamValue, setQueryParam } = useAppSettingQueryParam(
    `${appSettingName}AppSetting`,
    wayfinderAppSettingDefinitions[name].controlType
  );

  const doneLoading = !isLoading; // An app setting is done loading when its value has been asynchronously loaded from the persisted state
  const needsInit = value === undefined; // A value is only undefined if it has not been initialized
  const hasUnappliedQueryParameter = // A query parameter is undefined if it is not in the query string
    queryParamValue !== undefined && queryParamValue !== value;
  if (doneLoading && (needsInit || hasUnappliedQueryParameter)) {
    const initialValue =
      queryParamValue !== undefined
        ? queryParamValue
        : wayfinderAppSettingDefinitions[name].defaultValue;
    setValue(initialValue as V);
  }
  if (queryParamValue !== undefined && value === queryParamValue)
    setQueryParam(undefined, "replace");

  return useMemo(
    () => ({
      value:
        value === undefined
          ? ((wayfinderAppSettingDefinitions[name]
              .defaultValue as unknown) as V)
          : value,
      setValue,
      isLoading,
      isError,
      isDefault:
        (value as any) === wayfinderAppSettingDefinitions[name].defaultValue,
      isInitialized: value !== undefined,
      config: wayfinderAppSettingDefinitions[name],
    }),
    [isError, isLoading, name, setValue, value]
  );
}

const useAppSettingQueryParam = (
  queryParam: string,
  type: AppSetting<number | boolean | string>["controlType"]
) => {
  const queryParamConfig =
    type === "number"
      ? NumberParam
      : type === "switch"
      ? BooleanParam
      : StringParam;
  const [queryParamValue, setQueryParam] = useQueryParam<any, any>(
    queryParam,
    queryParamConfig
  );
  return useMemo(() => ({ queryParamValue, setQueryParam }), [
    queryParamValue,
    setQueryParam,
  ]);
};

/*
 * Utility component that will wait until the specified AppSettings have loaded
 *  before rendering its children.
 */
export const WaitForAppSettings: React.FC<{
  settings: (keyof WayfinderAppSettingTypes)[];
}> = ({ settings, children }) => {
  const [initialized, setInitialized] = useState(
    {} as Record<WayfinderAppSettingName, boolean>
  );

  const updateInitialized = useCallback((setting: WayfinderAppSettingName) => {
    setInitialized((old) => ({ ...old, [setting]: true }));
  }, []);

  const allInitialized = settings.map((s) => initialized[s]).every(Boolean);

  return (
    <>
      {settings.map((s) => (
        <WaitForSingleAppSetting
          key={s}
          setting={s}
          onInitialized={updateInitialized}
        />
      ))}
      {allInitialized && children}
    </>
  );
};

/** Used by the above `WaitForAppSettings` component */
const WaitForSingleAppSetting: React.FC<{
  setting: WayfinderAppSettingName;
  onInitialized: (setting: WayfinderAppSettingName) => void;
}> = ({ setting, onInitialized }) => {
  const { value, isLoading } = useAppSetting(setting);
  const [initialized, setInitialized] = useState<boolean>(false);
  useEffect(() => {
    if (value !== undefined && !isLoading && !initialized) {
      setInitialized(true);
      onInitialized(setting);
    }
  }, [initialized, isLoading, onInitialized, setting, value]);
  return null;
};
