import { SxProps, createTheme } from "@mui/material/styles";
import { ZoneType } from "@sofarocean/wayfinder-typescript-client";
import { RouteStyle } from "../components/WeatherAlongRoutePlot";

const spotterYellow = "#EFCF16";
const navy = "#262A3F";
const turquoise = "#00807d";
const comparisonOrange = "#FF6D00";

const areaConstraintPalette: Record<ZoneType, string> = {
  "no-go-zone": "#FF6666",
  "limited-passage-zone": "#692E7E",
  "speed-control-zone": spotterYellow,
  "eca-zone": "#3DB535",
  "whale-protection-zone": "#2E69ff",
  "high-risk-zone": "#73000B",
  "strait-zone": "#DEEEFF",
  "port-zone": "#CB7DFF",
};

export const extendedPalette = {
  white: "#fff",
  black: "#111",
  surface: "#000",
  primary: "#096ED3",
  neutralDark: "#677E98",
  neutralSlightlyDark: "#778B9E",
  neutral: "#899AAD",
  neutralAccess: "#4B5B6B",
  neutralMedDark: "#A8B4C1", // FIXME these are out of sync wit the mocks. There is no "neutral", and it all shifts
  neutralMedium: "#C4CCD6",
  neutralMedLight: "#D6DCE2",
  neutralLight: "#E5E9ED",
  neutralWhisper: "#F3F5F7",
  navy,
  turquoise,
  sea: "#AADAFF",
  seaLight: "#d4ecff", // sea at 0.5 opacity
  ocean: "#7ABAF9",
  orange100: "#FFEDD5",
  orange600: "#EA580C",
  marine: "#0050A0",
  red: "#FF6666",
  yellow: "#FFA533",
  spotterYellow,
  uiLightBlue: "#D9EBFD",
  uiVeryLightBlue: "#ECF2FB",
  comparisonOrange,
  comparisonOrangeDark: "#ba5c23",
  callout: { good: "#08C4A4", fair: "#FFA533", poor: "#FF6666" },
  print: {
    ocean: "#C3E6E2",
    land: "#FFF3DF",
    waves: ["#7ABAF9", "#729FE4", "#30A580", "#F9D68F", "#F78986", "#751F1B"],
  },
  banner: navy,
  routes: {
    activeRoute: {
      vesselColor: navy,
      color: navy,
    },
    suggestedRoute: {
      vesselColor: "#FF01B8",
      color: "#FF01B8",
    },
    sailedRoute: {
      vesselColor: "#fff",
      color: "#fff",
    },
    basisRoute: {
      vesselColor: "#A8B4C1",
      color: "#A8B4C1",
    },
    comparisonRoutes: {
      colors: [
        comparisonOrange,
        "#038415",
        "#6933FF",
        "#B806F9",
        "#FAFF02",
        "#08FD03",
        "#886F65",
        "#00FFF0",
      ],
    },
    routeRequest: {
      vesselColor: "#677E98",
      color: "#FFA533",
    },
    goodWeather: {
      color: "#61A2E57F",
    },
  },
  spotter: {
    main: spotterYellow,
  },
  comparisonRed: "#FF6666",
  comparisonGreen: "#028C72",
  areaConstraintZoneType: areaConstraintPalette,
  safety: {
    rollAngle: "#3366F6",
    pitchAngle: "#0094A3",
    parametricRoll: "#CC3B15",
    synchronousRoll: "#FF8EEF",
    highWave: "#99FAFF",
    broaching: "#8D47B2",
    combined: "#FFFFFF",
  },
  weatherDirections: {
    seas: "#A8B4C1",
    swell: "#000",
    currents: "#4B5B6B",
  },
  tropicalStorms: {
    stormLine: "#FF6666",
    windDangerFill: "rgba(249,214,143, 0.25)", //#F9D68F 25%
    windDangerOutline: "#F9D68F",
    coneOfUncertaintyFill: "rgba(255,255,255, 0.4)",
    coneOfUncertaintyOutline: "#FFFFFF",
    windGt64Fill: "rgba(255, 102, 102, 0.25)",
    windGt64Outline: "#FF6666",
    wind49To63Fill: "rgba(255, 165, 51, 0.25)",
    wind49To63Outline: "#FFA533",
    wind34To49Fill: "rgba(249,214,143, 0.25)",
    wind34To49Outline: "#F9D68F",
  },
  voyageStates: {
    planning: "#E07933",
    inProgress: "#30A580",
    completed: "#CC3B15",
  },
  ciiYtdRatingColors: {
    a: "#A0C984",
    b: "#D7E3B5",
    c: "#FFE699",
    d: "#F6B587",
    e: "#FF9999",
  },
  seaIceCoverage: {
    packIceZone: "#ff0000",
    marginalIceZone: "#ffff00",
  },
};

export const fontFamilies = {
  gilroy: `"Gilroy", "Arial", sans-serif`,
  georgia: `"Georgia", "Times New Roman", serif`,
  katexMath: `"KaTeX_Math", Georgia,Times New Roman,serif;`,
};

export const typographyStyles = {
  body: {
    fontFamily: fontFamilies.gilroy,
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "26.6px",
  },
  bodyLarge: {
    fontFamily: fontFamilies.gilroy,
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20.8px",
  },
  bodyStrong: {
    fontFamily: fontFamilies.gilroy,
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "26.6px",
  },
  displayLarge: {
    fontFamily: fontFamilies.gilroy,
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.36px", //2%
  },
  header: {
    fontFamily: fontFamilies.gilroy,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20.8px",
    letterSpacing: "0.32px", //2%
  },
  title: {
    fontFamily: fontFamilies.gilroy,
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "16.06px",
    letterSpacing: "0.25px", //2%
  },
  titleSmall: {
    fontFamily: fontFamilies.gilroy,
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "14.4px",
    letterSpacing: "0.25px", //2%
  },
  italics: {
    fontFamily: fontFamilies.georgia,
    fontWeight: 400,
    fontStyle: "italic",
    fontSize: "14px",
    lineHeight: "19.6px",
  },
  italicsSmall: {
    fontFamily: fontFamilies.georgia,
    fontWeight: 400,
    fontStyle: "italic",
    fontSize: "10px",
    lineHeight: "11.8px",
  },
  buttonSmall: {
    fontFamily: fontFamilies.gilroy,
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "16.9px",
    letterSpacing: "0.26px", //2%
  },
  buttonXtraSmall: {
    fontFamily: fontFamilies.gilroy,
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16.9px",
    letterSpacing: "0.26px", //2%
  },
  displaySmall: {
    fontFamily: fontFamilies.gilroy,
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "22.8px",
    letterSpacing: "0.36px", //3%
  },
  displayXtraSmall: {
    fontFamily: fontFamilies.gilroy,
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "14.4px",
    letterSpacing: "0.36px", //3%
  },
  formLabel: {
    fontFamily: fontFamilies.gilroy,
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14.4px",
    letterSpacing: "0.36px", //3%
  },
  label: {
    fontFamily: fontFamilies.gilroy,
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "13px",
    letterSpacing: "0.3px", //3%
  },
  labelSmall: {
    fontFamily: fontFamilies.gilroy,
    fontSize: "8px",
    fontWeight: 600,
    lineHeight: "10.8px",
  },
  math: {
    fontFamily: fontFamilies.katexMath,
    fontWeight: 400,
    fontStyle: "italic",
    fontSize: "16px",
    lineHeight: "22px",
  },
  tabular: {
    fontFamily: fontFamilies.gilroy,
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "24px",
    letterSpacing: "0.6px", //5%
  },
};
const styleToEms = (style: {
  fontSize: string;
  lineHeight: string;
  letterSpacing?: string;
}) => {
  return {
    ...style,
    fontSize: `${parseFloat(style.fontSize) / 16}em`,
    lineHeight: `${parseFloat(style.lineHeight) / 16}em`,
    ...(style.letterSpacing
      ? {
          letterSpacing: `${parseFloat(style.letterSpacing) / 16}em`,
        }
      : {}),
  };
};
export const typographyStylesEms = Object.fromEntries(
  Object.entries(typographyStyles).map(([key, value]) => [
    key,
    styleToEms(value),
  ])
);

export const activeRouteStyles: RouteStyle = {
  ...extendedPalette.routes.activeRoute,
  mapStrokeWidth: 3,
  showWaypoints: true,
  showDirectionalIndicator: true,
};
export const suggestedRouteStyles: RouteStyle = {
  ...extendedPalette.routes.suggestedRoute,
  mapStrokeWidth: 3,
  showWaypoints: false,
  showDirectionalIndicator: false,
};
export const sailedRouteStyles: RouteStyle = {
  ...extendedPalette.routes.sailedRoute,
  mapStrokeWidth: 3,
  showWaypoints: true,
  showDirectionalIndicator: true,
};
export const basisRouteStyles: RouteStyle = {
  ...extendedPalette.routes.basisRoute,
  mapStrokeWidth: 3,
  showWaypoints: false,
  showDirectionalIndicator: false,
};
export const routeRequestStyles: RouteStyle = {
  ...extendedPalette.routes.routeRequest,
};

export const comparisonRoutesStyles: RouteStyle[] = extendedPalette.routes.comparisonRoutes.colors.map(
  (c: string) => ({
    vesselColor: c,
    color: c,
    mapStrokeWidth: 3,
    showWaypoints: false,
  })
);

const spacingTheme = createTheme({
  spacing: 4,
}).spacing;

export const theme = createTheme({
  spacing: spacingTheme,
  palette: {
    primary: {
      main: extendedPalette.primary,
    },
    secondary: {
      main: "#262A3F",
    },
    error: {
      main: extendedPalette.red,
    },
  },
  typography: {
    fontFamily: fontFamilies.gilroy,
    fontSize: 14,
    body1: {
      lineHeight: "160%",
    },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          "&:focus": {
            backgroundColor: "$labelcolor",
          },
        },
      },
    },
  },
  shape: {
    borderRadius: 0,
  },
});

export const buttonStyle: SxProps = {
  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor: "inherit",
  },
};
